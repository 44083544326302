import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import SelectCarrier from '../../components/SelectCarrier/SelectCarrier';
import SelectCountry from '../../components/SelectCountry/SelectCountry';
import { getRates, hashRate } from '../../ducks/rates';
import { connect } from 'react-redux';
import { ProgressBar } from '../../components';
import { formatRatesPrice } from '../../utils/priceFormatter';

const Data = props => {
	const { t } = useTranslation();

	const [country, setCountry] = React.useState();
	const [carrier, setCarrier] = React.useState();
	const rate = carrier
		? props.rates[hashRate(carrier.value, carrier.value)]
		: null;
	const dataCost = rate && rate.CostData ? formatRatesPrice(rate.CostData) : '';
	const handleSubmit = () => {
		props.getRates({ location: carrier.value, destination: carrier.value });
	};
	return (
		<div>
			<div className="admin-rates-card">
				<div className="admin-rates-card-header">
					<h4>{t('Source')}</h4>
					<img src={process.env.PUBLIC_URL + '/assets/note.png'} alt="note" />
					<span>?</span>
				</div>
				<div className="admin-rates-card-body">
					<div className="admin-rates-action-row">
						<div className="row">
							<label>{t('Country')}</label>
							<SelectCountry value={country} onChange={c => setCountry(c)} />
						</div>
						<div className="row">
							<label>{t('Carrier')}</label>
							<SelectCarrier
								countryIsoCode={country && country.value}
								value={carrier}
								onChange={c => setCarrier(c)}
							/>
						</div>
						<div>
							<button
								className="admin-rates-button"
								type="button"
								onClick={handleSubmit}>
								{t('Calculate')}
							</button>
						</div>
					</div>
				</div>
			</div>
			{props.loading && <ProgressBar />}

			{dataCost && (
				<div className="row">
					<div className="row admin-rates-call-info">
						<img
							src={process.env.PUBLIC_URL + '/assets/data-color.png'}
							alt="data"
						/>
						<span>
							<b>{ dataCost } </b>
							<Trans>
								Per 1MB
							</Trans>
						</span>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		loading: state.rates.fetching,
		rates: state.rates.byLocationAndDestination
	};
};

const dispatchProps = {
	getRates
};
export default connect(
	mapStateToProps,
	dispatchProps
)(Data);
