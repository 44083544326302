import React from 'react';
import { Trans } from 'react-i18next';

type Props = {
	shippingMethod: any
};
const PickupOptions = (props: Props) => {
	if (!props.shippingMethod) {
		return null;
	}
	const PARTNER_URL =
		'https://www.partner.co.il/servicecenters#!/servicecenterland';
	if (props.shippingMethod.title.en.toLowerCase().includes('partner'))
		return (
			<div className="pickup-container">
				<b>
					<u>
						<Trans>Pickup location</Trans>:
					</u>
				</b>
				<p>
					<b>
						<Trans>Partner service center</Trans>
					</b>
					<br />
					<Trans>
						Please make sure to choose only service centers in the following
						link
					</Trans>
					<br />
					<a href={PARTNER_URL} target="_blank" rel="noopener noreferrer">
						<u>
							<b>https://www.partner.co.il</b>
						</u>
					</a>
				</p>
			</div>
		);
	return (
		<div className="pickup-container">
			<b>
				<u>
					<Trans>Pickup location</Trans>:
				</u>
			</b>
			<p>
				<b>
					<Trans>Omnitelecom's office</Trans>
				</b>
				<br />
				<Trans>
					125 Bialik St. Ramat Gan, Israel.
				</Trans>
				<br />
				<Trans>
					Opening hours
				</Trans>
				{': '}
				<Trans>Sun-Thu, 9am-24pm</Trans>
			</p>
		</div>
	);
};

export default PickupOptions;
