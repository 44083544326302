import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import classNames from 'classnames';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import './nav-bar.css';
function NavBar({
	next,
	disabled,
	prev,
	history,
	match: {
		params: { locale }
	}
}) {
	const isRTL = locale === 'he';
	const { t } = useTranslation();
	return (
		<div
			className={classNames({
				'navigation-container': true,
				rtl: isRTL
			})}>
			<button
				type="button"
				className="navigation-container-disabled"
				onClick={history.goBack}>
				<FontAwesomeIcon
					icon={isRTL ? 'chevron-right' : 'chevron-left'}
					className="navigation-arrow"
				/>
				{t('Go Back')}
			</button>
			{next ? (
				<Link
					className={classNames({
						'navigation-container-active': true,
						'navigation-container-disabled': disabled,
						'disabled-link': disabled
					})}
					to={next}>
					{t('Continue')}
					<FontAwesomeIcon
						icon={!isRTL ? 'chevron-right' : 'chevron-left'}
						className="navigation-arrow"
					/>
				</Link>
			) : (
				<span />
			)}
		</div>
	);
}

export default withRouter(NavBar);
