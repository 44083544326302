import React from 'react';
import { Content, Footer, Header } from '..';
import { withRouter } from 'react-router-dom';
import './styles.css';
import ScreenTitle from '../ScreenTitle/ScreenTitle';
import { Helmet } from 'react-helmet';
import { useTranslation } from 'react-i18next';
import CustomFooter from '../CustomFooter/CustomFooter'

function Screen(props) {
	const { title, customFooterContent } = props;
	const { t } = useTranslation();
	return (
		<>
			<div className="screen-wrapper">
				<Helmet
					titleTemplate="Omnitelecom | %s"
					title={title}
					defaultTitle={t("Omnitelecom | Order your SIM card to any destination | SIM cards for Israel, USA, Europe and the Far east")}>
					<meta name="description" content={t('meta:description')} />
				</Helmet>
				<Header />

				{title && <ScreenTitle theme={props.theme}>{title}</ScreenTitle>}
				<div className="main-background-image" />
				<Content>{props.children}</Content>
				<div className="footer-background">
					<div className="bottom-background-image" />
				</div>
				{customFooterContent && <CustomFooter footerStyle={props.footerStyle}>{customFooterContent}</CustomFooter>}
				<Footer />
			</div>
		</>
	);
}

export default withRouter(Screen);
