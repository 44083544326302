import { createReducer } from 'redux-starter-kit';
import apiConfig from '../config/apiConfig';

const initialState = {
	fetching: false,
	errors: null,
	byLocationAndDestination: {}
};

export function hashRate(location, destination) {
	return (location + ':' + destination).toLowerCase();
}

const actions = {
	'@@rates/FETCH_REQUEST': (state, action) => ({
		...state,
		fetching: true
	}),
	'@@rates/FETCH_SUCCEEDED': (state, action) => {
		state.fetching = false;
		state.byLocationAndDestination[
			hashRate(action.payload.location, action.payload.destination)
		] = action.payload;
	},
	'@@rates/FETCH_FAILED': (state, action) => {
		state.fetching = false;
		state.error = action.payload;
	}
};
const ratesReducer = createReducer(initialState, actions);
export default ratesReducer;

export function getRates({ location, destination }) {
	return function(dispatch, getState) {
		if (
			getState().rates.byLocationAndDestination[hashRate(location, destination)]
		) {
			return;
		}
		dispatch({ type: '@@rates/FETCH_REQUEST' });
		fetch(apiConfig.apiUrl + '/sims/rates/', {
			method: 'POST',

			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				location,
				destination
			})
		})
			.then(async response => {
				const payload = await response.json();
				if (!payload.success) {
					dispatch({
						type: '@@rates/FETCH_FAILED',
						payload: payload.error
					});
					return;
				}

				dispatch({
					type: '@@rates/FETCH_SUCCEEDED',
					payload: { ...payload.data, location, destination }
				});
			})
			.catch(e => {
				dispatch({ type: '@@rates/FETCH_FAILED', payload: String(e) });
			});
	};
}
