import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import './styles.css';

function DropDown(props) {
	const { options, getLabel = item => item, value, onChange } = props;
	var show = false;

	function hover() {
		console.log("hover is:"+show)
		show = !show;
	}
		
	return (
		<div className="tast dropdown" >
			<div className="dropdown-block">
				<FontAwesomeIcon icon="angle-down" className="icon" />
				<p>{getLabel(value)}</p>
			</div>
			<div className="options-dropdown">
				{options.map(item => {
					return (
						<div
							key={getLabel(item)}
							className="options-dropdown-item"
							onClick={() => onChange(item)}>
							{getLabel(item)}
						</div>
					);
				})}
			</div>
		</div>
	);
}

export default DropDown;
