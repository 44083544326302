import React from 'react';
import { Button, Input } from '../../components';
import { Trans, useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import apiConfig from '../../config/apiConfig';

const ResetPassword = props => {
	const [email, setEmail] = React.useState('');
	const { t } = useTranslation();
	const isRTL = props.match.params.locale === 'he';
	const handleChange = e => setEmail(e.target.value);
	const handleSubmit = () => {
		resetPassword(email);
	};
	function resetPassword(email) {
		return fetch(apiConfig.apiUrl + '/auth/password/reset', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				email,
				locale: props.match.params.locale
			})
		})
			.then(() => {
				props.history.replace(props.match.url + '/confirm');
			})
			.catch(e => {
				console.log(e);
			});
	}
	return (
		<div
			style={{
				display: 'flex',
				flexDirection: 'column',
				alignItems: 'center',
				padding: 40,
				direction: isRTL ? 'rtl' : 'ltr'
			}}>
			שכחת את הסיסמה? הזן את כתובת הדואר אלקטרוני ותקבל קישור לאיפוס סיסמה.
			<br />
			<br />
			<Input
				placeholder={t('Email address')}
				type="email"
				value={email}
				onChange={handleChange}
				style={{
					width: '100%',
					padding: 10
				}}
			/>
			<br />
			<br />
			<Button onClick={handleSubmit}>
				<Trans>Reset password</Trans>
			</Button>
		</div>
	);
};

const mapStateToProps = state => {
	return {};
};
export default connect(mapStateToProps)(ResetPassword);
