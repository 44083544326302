import { createReducer } from 'redux-starter-kit';
import apiConfig from '../config/apiConfig';

const initialState = {
	fetching: false,
	errors: null,
	success: false
};

const actions = {
	'@@contactUs/FETCH_REQUEST': (state, action) => ({
		...state,
		fetching: true
	}),
	'@@contactUs/FETCH_SUCCEEDED': (state, action) => {
		state.fetching = false;
		state.success = true;
	},
	'@@contactUs/FETCH_FAILED': (state, action) => {
		state.fetching = false;
        state.error = action.payload;
        console.log(action.payload);
    },
    '@@contactUs/CLEAR_STATE': (state, action) => (initialState)
};
const contactUsReducer = createReducer(initialState, actions);
export default contactUsReducer;

export function clearState() {
    console.log("state cleared");
    return function(dispatch, getState){
        dispatch({type:'@@contactUs/CLEAR_STATE'});
    }
}

export function postContactUs(values) {
    return function(dispatch, getState){
        dispatch({ type: '@@contactUs/FETCH_REQUEST' });
        fetch(apiConfig.apiUrl + '/contact/', {
            method: 'POST',
            headers: {
				'Content-Type': 'application/json',
            },
            body: JSON.stringify(values)
        })
            .then(async response => {
                const payload = await response.json();
                if (!payload.success) {
					dispatch({
						type: '@@contactUs/FETCH_FAILED',
						payload: payload.error
					});
					return;
                }               
                dispatch({
					type: '@@contactUs/FETCH_SUCCEEDED',
					payload: { ...payload.data, values }
                });
            })
			.catch(e => {
				dispatch({ type: '@@contactUs/FETCH_FAILED', payload: String(e) });
            });
    };
};

