//@flow
import React from 'react';
import {
    Screen,
    PackageCard,
    //	Snack,
    Tooltip,
    NavBar
} from '../../components';
import {useTranslation, Trans} from 'react-i18next';
import classNames from 'classnames';
import {connect} from 'react-redux';
import { Link } from 'react-router-dom';
import './category.css';
//import SimNumberInput from '../../components/SimNumberInput/SimNumberInput';
import {getSimPlans} from '../../ducks/simPlans';
import {planTypeCodeMapper} from '../../utils/categoryMapper';

function Category(props) {
    const {t} = useTranslation();
    React.useEffect(function () {
        props.getSimPlans(planTypeCodeMapper(category));
    }, []);
    const {
        match: {
            params: {locale, category}
        }
    } = props;
    const isRTL = locale === 'he';
    return (
        <Screen title={t('titles:' + category)}>
            <div className="category-screen-container">

                    <span className={isRTL ? 'tooltip-ltr' : 'tooltip-rtl'}>
                        <Tooltip title={t('Pay attention')}
                                 text={t('Please verify your phone is compatible with a foreign SIM card')}/>
                    </span>
                {category === 'israeli-sim' &&
                    <div className={classNames({
                        'order-screen-megaphone': true,
                        rtl: isRTL
                    })}>
						<span>
							<img
                                src={process.env.PUBLIC_URL + '/assets/speaker.png'}
                                alt="speaker"
                                className="speaker-icon"
                            />
						</span>
                        <span className={'order-screen-megaphone-text-bold'}>{t('For your convenience, ')}</span>
                        <span
                            className={'order-screen-megaphone-text'}>{t('you can charge our SIM multiple times every time you visit Israel!')}</span>
                    </div>
                }
                <div className="order-screen-heading">
                    <span>{t('Stage {{stage}} of 3', {stage: 1})}</span>
                </div>

                <br/>
                <div
                    className={classNames({
                        'category-screen-title': true,
                        rtl: isRTL
                    })}>
                    <span>{t('Please select a SIM card')}</span>
                </div>
                <div className="package-list-container">
                    {props.simPlans.map(simPlan => {
                        return <PackageCard key={simPlan} simPlanId={simPlan}/>;
                    })}
                </div>
                {planTypeCodeMapper(props.match.params.category) === 'eur' && 
                    (
                        <div className="order-screen-footer"> 
                            <Trans>
				    	    For the full list of European countries 
				    	    <Link style={{paddingLeft:'1vw'}} id="red-txt-btn" to={'/' + props.match.params.locale + '/countries'}>
				    	    	click here
				    	    </Link>
				        </Trans>
                        </div>
                    )
                } 
                <NavBar disabled={!props.valid} next={props.match.url + '/order'}/>
            </div>
        </Screen>
    );
}

const mapStateToProps = (state, props) => {
    const planTypeCode = planTypeCodeMapper(props.match.params.category);
    return {
        valid: Object.keys(state.order.items).find(
            key => state.order.items[key].quantity > 0
        ),
        simPlans: state.simPlans[planTypeCode]
            ? state.simPlans[planTypeCode].items
            : []
    };
};
export default connect(
    mapStateToProps,
    {getSimPlans: getSimPlans}
)(Category);
