import { createReducer, createAction } from 'redux-starter-kit';
import apiConfig from '../config/apiConfig';
import { signOut } from './auth';

const initialState = {
	selectedSimNumber: '',
	bySimNumber: {},
	topUp: {
		success: false,
		errors: '',
		fetching: false
	}
};

const fetchBalance = createAction('@@account/fetch_balance');
const fetchBalanceSuccess = createAction('@@account/fetch_balance_success');
const fetchBalanceFailed = createAction('@@account/fetch_balance_failed');
export const selectSimNumber = createAction('@@account/sim_number_selected');
export const clearTopUp = createAction('@@action/clear_top_up');
const topUpRequest = createAction('@@account/topup_request');
const topUpRequestSuccess = createAction('@@account/topup_request_success');
const topUpRequestFailed = createAction('@@account/topup_request_failed');

const actions = {
	[clearTopUp]: (state, action) => {
		state.topUp = initialState.topUp;
	},
	[fetchBalance]: (state, action) => {
		state.bySimNumber[state.selectedSimNumber] = {
			fetching: true
		};
	},
	[fetchBalanceSuccess]: (state, action) => {
		state.bySimNumber[state.selectedSimNumber] = {
			fetching: false,
			balance: action.payload
		};
	},
	[fetchBalanceFailed]: (state, action) => {
		state.bySimNumber[state.selectedSimNumber] = {
			fetching: false,
			error: action.payload
		};
	},
	[selectSimNumber]: (state, action) => {
		state.selectedSimNumber = action.payload;
	},
	[topUpRequest]: (state, action) => {
		state.topUp.fetching = true;
	},
	[topUpRequestFailed]: (state, action) => {
		state.topUp.errors = action.payload;
		state.topUp.fetching = false;
	},
	[topUpRequestSuccess]: (state, action) => {
		state.topUp.fetching = false;
		state.topUp.success = true;
	},
	[signOut]: (state, action) => {
		return initialState;
	}
};
const accountReducer = createReducer(initialState, actions);
export default accountReducer;

export function getBalance(simCard) {
	return function(dispatch, getState) {
		dispatch(selectSimNumber(simCard));
		dispatch(fetchBalance());
		fetch(apiConfig.apiUrl + '/accounts/balance/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + getState().auth.accessToken
			},
			body: JSON.stringify({
				sim_number: simCard
			})
		})
			.then(async res => {
				if (!res.ok) {
					return dispatch(fetchBalanceFailed(res.statusText));
				}
				const payload = await res.json();
				dispatch(fetchBalanceSuccess(payload.data.balance));
			})
			.catch(e => {
				console.log(e);
				dispatch(fetchBalanceFailed(String(e)));
			});
	};
}

export function makeTopUp(values) {
	const CREDITS_PER_UNIT = 10;
	return function(dispatch, getState) {
		dispatch(topUpRequest());
		const simNumber = getState().account.selectedSimNumber;
		const userFullName = `${getState().auth.currentUser.first_name} ${getState().auth.currentUser.last_name}`;
		const creditsPrePurchase = parseFloat(getState().account.bySimNumber[simNumber].balance);
		const creditsPurchased = values.quantity * CREDITS_PER_UNIT;
		const creditsTotal = Math.floor((creditsPrePurchase + creditsPurchased) * 100) / 100;
		console.log(creditsTotal);
		const email = getState().auth.currentUser.email;
		const locale = getState().appState.locale;
		fetch(apiConfig.apiUrl + '/orders/charge/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + getState().auth.accessToken
			},
			body: JSON.stringify({
				cc_owner_name: values.billing.cardHoldersName,
				cc_number: values.billing.creditCardNumber,
				cc_valid_thru: values.billing.expiration.replace('/', ''),
				cc_cvv: values.billing.cvv,
				credits_purchased: creditsPurchased,
				sim_number: simNumber,
				user_full_name: userFullName,
				credits_total: creditsTotal,
				email: email,
				locale: locale
			})
		})
			.then(async res => {
				const payload = await res.json();
				if (!payload.success) {
					return dispatch(topUpRequestFailed(payload.errors));
				}
				dispatch(topUpRequestSuccess());
			})
			.catch(e => {
				dispatch(topUpRequestFailed(String(e)));
			});
	};
}
