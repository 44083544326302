import React from 'react';
import './screen-card.css';
function ScreenCard({ title, subtitle, children }) {
	function renderTitle() {
		if (typeof title === 'string') {
			return <span>{title}</span>;
		}
		if (typeof title === 'function') {
			return title();
		}
		return title;
	}
	return (
		<div className="screen-card-container">
			<div className="screen-card-title">{renderTitle()}</div>
			{subtitle && subtitle}
			<div className="screen-card-content">{children}</div>
		</div>
	);
}
export default ScreenCard;
