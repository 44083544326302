import React from 'react';
import { Screen } from '../../components';
import apiConfig from '../../config/apiConfig';
import './styles.css';
function NotFound(props) {
	const [data, setData] = React.useState();
	React.useEffect(() => {
		const { locale } = props.match.params;
		const slug = props.location.pathname.split('/').slice(-1)[0];
		fetch(apiConfig.apiUrl + `/pages/?language=${locale}&slug=${slug}`).then(
			async res => {
				const payload = await res.json();
				if (payload.count) {
					setData(payload.results[0]);
				}
			}
		);
	}, [props.match.params.locale, props.location.pathname]);
	if (!data) return <Screen />;
	return (
		<Screen>
			<div className="omni-page">
				<h1>{data.title}</h1>
				<div dangerouslySetInnerHTML={{ __html: data.content }} />
			</div>
		</Screen>
	);
}

export default NotFound;
