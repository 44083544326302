//@flow
import React from 'react';
import './radio-group.css';
import classNames from 'classnames';

type RadioOptionType = {
	value: any,
	label: string | (() => React.ElementType | React.ElementType)
};

type RadioGroupProps = {
	options: Array<RadioOptionType>,
	name: string,
	value: any,
	onChange: Function
};

function RadioGroup(props: RadioGroupProps) {
	const { options = [], name, value, onChange } = props;
	return (
		<div className="omni-radio-group">
			{options.map(option => {
				return (
					<RadioOption
						onClick={() => onChange(option.value)}
						key={option.value.toString()}
						name={name}
						{...option}
						selected={value === option.value}
					/>
				);
			})}
		</div>
	);
}

function RadioOption({ label, selected, onClick, ...rest }) {
	function renderLabel() {
		if (typeof label === 'string') return label;
		if (typeof label === 'function') return label();
		return label;
	}
	return (
		<label className="omni-radio-option clickable" onClick={onClick}>
			<div className="omni-radio">
				<div className={classNames({ 'omni-radio-selected': selected })} />
			</div>
			{renderLabel()}
		</label>
	);
}
export default RadioGroup;
