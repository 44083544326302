//@flow
import { createReducer, createAction } from 'redux-starter-kit';
import { normalize, schema } from 'normalizr';
import apiConfig from '../config/apiConfig';
import ReactGA from 'react-ga';

function normalizeSimPlan(data) {
	//console.log("hello")
	/*
	ReactGA.event({
		category: 'User',
		action: 'Get Sim Plans'
	  });
	*/
	const simPlan = new schema.Entity(
		'simPlan',
		{},
		{
			idAttribute: 'plan_id',
			processStrategy: item => {
				return {
					...item,
					description_he: item.description_he.split('|').filter(item => item),
					description_en: item.description_en.split('|').filter(item => item)
				};
			}
		}
	);
	const simPlanSchema = [simPlan];

	return normalize(data, simPlanSchema);
}

const initialState = {
	fetching: false,
	errors: '',
	byId: {}
};

const simPlanRequest = createAction('@@simPlans/SIM_PLANS_REQUESTED');
const simPlanRequestSuccess = createAction(
	'@@simPlans/GET_SIM_PLANS_SUCCEEDED'
);
const simPlanRequestFail = createAction('@@simPlans/GET_SIM_PLANS_Failed');

const actions = {
	[simPlanRequest]: (state, action) => {
		state.fetching = true;
	},
	[simPlanRequestSuccess]: (state, action) => {
		state.fetching = false;

		const { data } = action.payload;
		const { entities, result } = normalizeSimPlan(data);
		state.byId = { ...state.byId, ...entities.simPlan };
		state[action.meta.planType] = {
			items: result,
			timestamp: Date.now(),
			fetching: false
		};
	},
	[simPlanRequestFail]: (state, action) => {
		state.fetching = false;
		state.errors = action.payload;
	}
};

export function getSimPlans(planType: string) {
	return function(dispatch, getState) {
		const url =
			planType === 'global'
				? apiConfig.apiUrl + '/simPlans/?is_global=1'
				: apiConfig.apiUrl + '/simPlans/?is_global=0&category=' + planType;
		return fetch(url)
			.then(async res => {
				if (!res.ok) {
					return dispatch(simPlanRequestFail());
				}
				const payload = await res.json();

				dispatch({
					type: '@@simPlans/GET_SIM_PLANS_SUCCEEDED',
					payload: { data: payload.results },
					meta: { planType },
					path:window.location.pathname
				});
			})
			.catch(e => {
				return dispatch(simPlanRequestFail(String(e)));
			});
	};
}

const simPlansReducer = createReducer(initialState, actions);

export default simPlansReducer;
