import React from 'react';
import './admin-rates-tab-bar.css';
import { withRouter, NavLink } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

function AdminRatesTabBar(props) {
	const {
		match: { url }
	} = props;
	const { t } = useTranslation();
	return (
		<div className="admin-rates-navbar-container">
			<div>
				<NavLink
					className="admin-rates-link"
					to={url + '/calls'}
					activeClassName="admin-rates-link-active">
					<div>
						<img
							src={process.env.PUBLIC_URL + '/assets/call.png'}
							alt="call rates"
						/>
						<span>{t('Call rates')}</span>
					</div>
				</NavLink>
				<NavLink
					className="admin-rates-link"
					to={url + '/sms'}
					activeClassName="admin-rates-link-active">
					<div>
						<img
							src={process.env.PUBLIC_URL + '/assets/sms.png'}
							alt="sms rates"
						/>
						<span>{t('SMS rates')}</span>
					</div>
				</NavLink>
				<NavLink
					className="admin-rates-link"
					to={url + '/data'}
					activeClassName="admin-rates-link-active">
					<div>
						<img
							src={process.env.PUBLIC_URL + '/assets/data.png'}
							alt="data rates"
						/>
						<span>{t('Data rates')}</span>
					</div>
				</NavLink>
			</div>
		</div>
	);
}

export default withRouter(AdminRatesTabBar);
