import { createReducer } from 'redux-starter-kit';
import { normalize, schema } from 'normalizr';
import apiConfig from '../config/apiConfig';

const initialState = {
	byId: {},
	allIds: [],
	fetching: false
};

const actions = {
	SHIPPING_METHODS_SUCCEEDED: (state, action) => {
		const { result, entities } = action.payload;

		state.allIds = result;
		state.byId = { ...state.byId, ...entities.shippingMethods };
	}
};

const shippingMethodsReducer = createReducer(initialState, actions);
export function getShippingMethods() {
	return function(dispatch, getState) {
		return fetch(apiConfig.apiUrl + '/shipping/methods/').then(async res => {
			if (!res.ok) return;
			const payload = await res.json();
			const shippingMethod = new schema.Entity(
				'shippingMethods',
				{},
				{
					idAttribute: 'id',
					processStrategy: (value, parent, key) => {
						return {
							id: value.id,
							title: {
								he: value.title_he,
								en: value.title_en
							},
							order: value.display_order,
							price: value.fee,
							currency: 'USD',
							isPickup: value.is_pickup
						};
					}
				}
			);
			const shippingMethodsSchema = [shippingMethod];

			dispatch({
				type: 'SHIPPING_METHODS_SUCCEEDED',
				payload: normalize(payload.results, shippingMethodsSchema),
				path:window.location.pathname
			});
		});
	};
}
export default shippingMethodsReducer;
