import React, { useEffect } from "react";
import ReactGA from "react-ga";
//import env from '../../config/envConfig';
import TagManager from 'react-gtm-module';

const trackingIds = {
  test: 'UA-156259855-1',
  dev: 'UA-153870386-1',
  prod: 'UA-153199948-1'
}

const tagManagerArgs = {
  gtmId: 'GTM-NXZR4KG'
}

export const configureAnalytics = () => {
  let useTrackingId = '';
  //console.log(env);
  // if(env === "test")
  //   useTrackingId = trackingIds.test;
  // else if(env === "dev")
  //   useTrackingId = trackingIds.dev;
  // else if(env === "prod")
  useTrackingId = trackingIds.prod;

  TagManager.initialize(tagManagerArgs);
  ReactGA.initialize(useTrackingId);
}

export const withAnalytics = (WrappedComponent, options = {}) => {
  const trackPage = page => {
    ReactGA.set({
      page,
      ...options
    });
    ReactGA.pageview(page);
  };

  const HOC = props => {
    useEffect(() => trackPage(props.location.pathname), [
      props.location.pathname
    ]);
    return <WrappedComponent {...props} />;
  };

  return HOC;
};