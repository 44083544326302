import { createReducer } from 'redux-starter-kit';
import apiConfig from '../config/apiConfig';

const initialState = {
	fetching: false,
	errors: null,
	data: []
};
const actions = {
	'@@countries/FETCH_REQUEST': (state, action) => ({
		...state,
		fetching: true
	}),
	'@@countries/FETCH_SUCCEEDED': (state, action) => {
		state.fetching = false;
		state.data = action.payload;
	},
	'@@countries/FETCH_FAILED': (state, action) => {
		state.fetching = false;
		state.error = action.payload;
	}
};
const countriesReducer = createReducer(initialState, actions);
export default countriesReducer;

export function getCountries() {
	return function(dispatch, getState) {
		if (getState().countries.data.length) {
			return;
		}
		dispatch({ type: '@@countries/FETCH_REQUEST' });
		fetch(apiConfig.apiUrl + '/sims/countries/')
			.then(async response => {
				const payload = await response.json();
				if (!payload.success) {
					dispatch({
						type: '@@countries/FETCH_FAILED',
						payload: payload.error
					});
				}
				dispatch({
					type: '@@countries/FETCH_SUCCEEDED',
					payload: payload.data
				});
			})
			.catch(e => {
				dispatch({ type: '@@countries/FETCH_FAILED', payload: String(e) });
			});
	};
}
