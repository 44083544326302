import React from 'react';
import './purchase-history.css';
import '../../components/Table/table.css';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { connect } from 'react-redux';
import SelectSimCard from '../../components/SelectSimCard/SelectSimCard';
import {
	getPurchaseHistory,
	clearPurchaseHistory
} from '../../ducks/purchaseHistory';
import { ProgressBar } from '../../components';
import { format, subMonths } from 'date-fns';

function PurchaseHistory(props) {
	const { t } = useTranslation();
	const {
		match: {
			params: { locale }
		}
	} = props;
	const isRTL = locale === 'he';

	const [simCard, setSimCard] = React.useState();
	const [startDate, setStartDate] = React.useState(
		format(subMonths(new Date(), 6), 'yyyy-MM-dd')
	);

	const [endDate, setEndDate] = React.useState(
		format(new Date(), 'yyyy-MM-dd')
	);

	const handleSubmit = () => {
		props.search({ phoneNumber: simCard && simCard.value, startDate, endDate });
	};

	React.useEffect(() => {
		return props.clear;
	}, []);
	return (
		<div
			className={classNames({
				'purchase-history-container': true,
				rtl: isRTL
			})}>
			<div className="purchase-history-form-container">
				<div>
					<label>{t('Phone number')}</label>
					<SelectSimCard
						value={simCard}
						onChange={c => setSimCard(c)}
						className="call-history-sim-card-select"
					/>
				</div>
				<div className="row space">
					<div>
						<label>{t('Start date')}</label>
						<input
							value={startDate}
							type="date"
							onChange={e => setStartDate(e.target.value)}
						/>
					</div>
					<div>
						<label>{t('End date')}</label>
						<input
							value={endDate}
							type="date"
							onChange={e => setEndDate(e.target.value)}
						/>
					</div>
					<button onClick={handleSubmit}>{t('Search')}</button>
				</div>
			</div>

			<div>
				<table className="omni-table">
					<thead>
						<tr>
							<td>{t('Date')}</td>
							<td>{t('Action ID')}</td>
							<td>{t('Cost')}</td>
							<td>{t('Status')}</td>
							<td>{t('Details')}</td>
							{/* <td>{t('Download')}</td> */}
						</tr>
					</thead>
					<tbody>
						{props.data.map(item => {
							return (
								<tr key={item.ReceiptId}>
									<td>{item.CreateDate}</td>
									<td>{item.ReceiptId}</td>
									<td>{item.Sum}</td>
									<td>{item.Status}</td>
									<td>{item.Description}</td>
									{/* <td>{''}</td> */}
								</tr>
							);
						})}
					</tbody>
					{props.fetching && (
						<tfoot>
							<tr>
								<td colSpan={5}>
									<ProgressBar />
								</td>
							</tr>
						</tfoot>
					)}
				</table>
			</div>
		</div>
	);
}

const mapStateToProps = state => {
	return state.purchaseHistory;
};

const dispatchProps = {
	search: getPurchaseHistory,
	clear: clearPurchaseHistory
};

export default connect(
	mapStateToProps,
	dispatchProps
)(PurchaseHistory);
