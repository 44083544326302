//old versino of countries component
//==================================
// import React from 'react';
// import { Page } from '../../components';
// import { connect } from 'react-redux';
// import { getPage } from '../../ducks/pages';

// function Countries(props) {
// 	React.useEffect(() => {
// 		props.getPage(1);
// 	}, []);
// 	console.log(props);
// 	return <Page {...props} title={props.title} content={props.content} />;
// }

// const mapStateToProps = state => {
// 	const page = state.pages.byId[1] || {};
// 	return { title: page.title, content: page.content };
// };
// export default connect(
// 	mapStateToProps,
// 	{ getPage }
// )(Countries);

import React from 'react';
import './styles.css';
import { Screen } from '../../components';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import countriesAllowed from '../../ducks/mocks/countriesAllowed.json';
import countriesAllowedDataOnly from '../../ducks/mocks/countriesAllowedDataOnly.json';

function Countries(props) {
	/*
	React.useEffect(() => {
		props.getPage(1);
	}, []);
	*/
	const {match:{params:{locale}}} = props;
	const isRTL = locale === 'he';
	const { t } = useTranslation();
	const countriesAllowedKeys = Object.keys(countriesAllowed);
	const countriesAllowedDataOnlyKeys = Object.keys(countriesAllowedDataOnly);

	return (
		<Screen title={t('titles:' + 'countries-list')}>
			<div className={classNames({ 'page-container': true, rtl: isRTL })}>
				<div className='page-title'>
					<span>{t('titles:' + 'countries-list')}</span>
				</div>
				<div className='page-content'>
					<h5>{t('List of destinations that can only be used for data plans, local calls, and local SMS messages')}:</h5>
					<ul className="listHorizontal">
						{countriesAllowedKeys.map(country => {return <li className = "countryLi" key = {country} >{t('countriesAllowed:' + country)}</li>})}
					</ul>
					<h5>{t('List of other destinations that can only be used for data plans (without calls and SMS messages)')}: </h5>
					<ul className="listHorizontal">
						{countriesAllowedDataOnlyKeys.map(country => {return <li className = "countryLi" key = {country}>{t('countriesAllowedDataOnly:' + country)}</li>})}
					</ul>
				</div>
			</div>
		</Screen>
	)
}

export default Countries


