//@flow
import React from "react";
import { Screen } from "../../components";
import { Trans, useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import classNames from "classnames";
import "./styles.css";
import PromoCard from "../../components/PromoCard/PromoCard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function HomeScreen(props) {
  const { t } = useTranslation();
  const {
    match: {
      url,
      params: { locale },
    },
  } = props;
  const isRTL = locale === "he";
  return (
    <>
      <Screen>
        <div className="main-screen-container">
          <div className="main-screen-heading">
            <Trans>
              <h1>Your next vacation</h1>
              <h2>starts here</h2>
            </Trans>
          </div>
          <br />
          <div
            className={classNames({
              "home-screen-row": true,
              rtl: isRTL,
            })}
          >
            <div className="home-screen-col start">
              <PromoCard
                category="usa"
                title={t("SIM CARD")}
                subtitle={t("USA, Canada and Mexico")}
                imageSrc={process.env.PUBLIC_URL + "/assets/red-card.png"}
              />
            </div>
            <div className="home-screen-col end">
              <PromoCard
                /* extra={() => (
									<div>
										<span className="card-charge-title">
											<Trans>Have an Israeli SIM?</Trans>
										</span>
										<Link to={url + '/' + categoryMapper('isr')}>
											<span className="card-charge-action blue">
												<Trans>Charge here</Trans>
												<span className="card-charge-action-arrow">
													<FontAwesomeIcon
														icon={isRTL ? 'chevron-left' : 'chevron-right'}
													/>
												</span>
											</span>
										</Link>
									</div>
								)} */
                category="isr"
                title={t("SIM CARD")}
                subtitle={t("Israel")}
                imageSrc={process.env.PUBLIC_URL + "/assets/blue-card.png"}
              />
            </div>
          </div>
          <div
            className={classNames({
              "home-screen-row": true,
              rtl: isRTL,
            })}
          >
            <div className="home-screen-col start">
              <PromoCard
                category="eur"
                title={t("SIM CARD")}
                subtitle={t("Europe and South East Asia")}
                imageSrc={process.env.PUBLIC_URL + "/assets/blue-card.png"}
              />
            </div>
            <div className="home-screen-col end">
              <PromoCard
                // extra={() => (
                //   <div>
                //     {/* <Link to={url + "/admin"}>
                //       <span className="card-charge-title">
                //         <Trans>Have an International SIM?</Trans>
                //       </span>
                //       <span className="card-charge-action red">
                //         <Trans>Charge and manage here</Trans>
                //         <span className="card-charge-action-arrow">
                //           <FontAwesomeIcon
                //             icon={isRTL ? "chevron-left" : "chevron-right"}
                //           />
                //         </span>
                //       </span>
                //     </Link> */}
                //   </div>
                // )}
                // category="Esim"
                // title={t("SIM CARD")}
                // subtitle={t("International")}
                // imageSrc={process.env.PUBLIC_URL + "/assets/red-card.png"}
                category="esim"
                title={t("SIM CARD")}
                subtitle={t("eSIM")}
                imageSrc={process.env.PUBLIC_URL + "/assets/red-card.png"}
              />
            </div>
          </div>
          <div
            className={classNames({
              row: true,
              "home-screen-links": true,
              rtl: isRTL,
            })}
          >
            <div className="home-screen-col start">
              {/* <div className="promo-card-footer">
                <Trans>
                  For the full tariff list for the Global Sim
                  <Link
                    style={{ paddingLeft: "1vw" }}
                    to={"/" + props.match.params.locale + "/country-rates"}
                  >
                    click here
                  </Link>
                </Trans>
              </div> */}
            </div>
            <div className="home-screen-col end">
              <div className="promo-card-footer red">
                <Trans>
                  For the full list of European countries
                  <Link
                    style={{ paddingLeft: "1vw" }}
                    to={"/" + props.match.params.locale + "/countries"}
                  >
                    click here
                  </Link>
                </Trans>
              </div>
            </div>
          </div>
        </div>
      </Screen>
    </>
  );
}

export default HomeScreen;
