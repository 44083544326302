import React from 'react';
import './button.css';
import classNames from 'classnames';

function Button(props) {
	return (
		<button
			className={classNames({
				'clickable omni-button': true,
				disabled: props.disabled,
				success: props.variant === 'success'
			})}
			{...props}>
			{props.children}
		</button>
	);
}

export default Button;
