import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components';
import { validateEmail } from '../../utils/validator';
import apiConfig from '../../config/apiConfig';

function changePassword({ email, new_password, re_new_password, token, uid }) {
	return fetch(apiConfig.apiUrl + '/auth/password/reset/confirm/', {
		method: 'POST',
		headers: {
			'Content-Type': 'application/json'
		},
		body: JSON.stringify({
			uid,
			new_password,
			re_new_password,
			token
		})
	});
}
function ChangePassword(props) {
	const [form, setForm] = React.useState({
		password: '',
		password2: '',
		email: '',
		error: ''
	});

	const handleChange = e => {
		setForm({ ...form, [e.target.name]: e.target.value });
	};

	function handleSubmit() {
		const { password, password2, email } = form;
		if (password.length < 8 || password2.length < 8) {
			return setForm({
				...form,
				error: 'Password must contain at leat 8 characters'
			});
		}

		if (password !== password2) {
			return setForm({
				...form,
				error: 'Make sure that the passwords are equal'
			});
		}

		if (!validateEmail(email)) {
			setForm({
				...form,
				error: 'Illegal email address'
			});
		}
		changePassword({
			email,
			uid: props.match.params.uid,
			new_password: password,
			re_new_password: password2,
			token: props.match.params.token
		}).then(response => {
			if (response.status === 204) {
				props.history.replace(
					'/' + props.match.params.locale + '/admin/sign-in'
				);
			} else {
				console.log('1');
				return response.json().then(_errors => {
					const errors = Object.keys(_errors).reduce((acc, cur) => {
						return [...acc, ..._errors[cur]];
					}, []);
					setForm({ ...form, error: errors[0] });
				});
			}
		});
	}
	const { t } = useTranslation();
	return (
		<div
			style={{
				padding: 20,
				dispay: 'flex',
				minWidth: 320,
				flexDirection: 'column',
				alignItems: 'center',
				textAlign: 'center',
				direction: props.match.params.locale === 'he' ? 'rtl' : 'ltr'
			}}>
			<h2>{t('Reset password')}</h2>

			<input
				name="email"
				type="email"
				value={form.email}
				onChange={handleChange}
				style={{
					width: '100%',
					padding: 5,
					marginBottom: 10
				}}
				placeholder={t('Email address')}
			/>

			<input
				type="password"
				name="password"
				value={form.password}
				onChange={handleChange}
				style={{
					width: '100%',
					padding: 5,
					marginBottom: 10
				}}
				placeholder={t('Password')}
			/>

			<input
				type="password"
				name="password2"
				value={form.password2}
				onChange={handleChange}
				style={{ width: '100%', padding: 5, marginBottom: 20 }}
				placeholder={t('Re-enter password')}
			/>

			{form.error && (
				<div style={{ marginBottom: 10 }}>
					<small style={{ color: '#ef3b27' }}>{t(form.error)}</small>
				</div>
			)}
			<Button onClick={handleSubmit} style={{ width: 150 }}>
				{t('submit')}
			</Button>
		</div>
	);
}

export default ChangePassword;
