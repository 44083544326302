import React, { useState } from 'react';
import './sign-in.css';
import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';
import { Redirect, Link } from 'react-router-dom';
import { reduxForm, Field } from 'redux-form';
import { connect } from 'react-redux';
import { signIn } from '../../ducks/auth';
import { Button } from '../../components';
let SignIn = props => {
	const { t } = useTranslation();
	const {
		match: {
			params: { locale }
		}
	} = props;
	const isRTL = locale === 'he';
	const [redirect, setRedirect] = useState(null);
	function handleSubmit({ email, password }) {
		props.signIn({ email, password }, user => {
			if (user) {
				setRedirect('/' + locale + '/admin');
			}
		});
	}
	if (redirect) {
		setRedirect(null);
		return <Redirect to={redirect} />;
	}
	return (
		<div
			className={classNames({
				'sign-in-container': true,
				rtl: isRTL
			})}>
			<div className="sign-in-header">
				<img
					src={process.env.PUBLIC_URL + '/assets/laptop.png'}
					alt="sign in"
				/>
				<h3>{t('Sign in')}</h3>
			</div>
			<form onSubmit={props.handleSubmit(handleSubmit)}>
				<table>
					<tbody>
						<tr>
							<td>
								<label>{t('Email address')}</label>
							</td>
							<td>
								<Field name="email" component="input" type="phone" />
							</td>
						</tr>
						<tr>
							<td>
								<label>{t('Password')}</label>
							</td>
							<td>
								<Field name="password" component="input" type="password" />
							</td>
						</tr>
						<tr>
							<td colSpan={2}>
								<Button>{t('Sign In')}</Button>

								{props.errors && (
									<center>
										<p className="submit-error">
											<Trans>
												Sign in failed.
												<br />
												Please check your credentials.
											</Trans>
										</p>
									</center>
								)}
							</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td
								style={{ textAlign: 'center', paddingTop: '10px' }}
								colSpan={2}>
								<Link
									to={
										'/' + props.match.params.locale + '/admin/password/reset'
									}>
									{t('Forgot your password')}?
								</Link>
							</td>
						</tr>
					</tfoot>
				</table>
			</form>
		</div>
	);
};

const mapStateToProps = state => {
	return {
		errors: state.auth.error
	};
};

SignIn = reduxForm({ form: 'signIn' })(SignIn);
SignIn = connect(
	mapStateToProps,
	{ signIn }
)(SignIn);
export default SignIn;
