import React from 'react';

function ContactInfo(props){
    let locale = props.locale;
    const isRTL = locale === 'he';
    return(
        <div className="contact-info">
            <div className="contact-info-element">
                <div className="contact-icon">
                    <img
                        src={process.env.PUBLIC_URL + '/assets/incoming-call.png'}
                        alt="contact-icon"
                    />
                </div>
                {isRTL ? 
                    (
                        <div>            
                            <div>למחלקת שירות הלקוחות -</div>
                            <div>customerservice@local03.com</div>
                            <a href="tel: 97237222711">97237222711</a>                   
                        </div>
                    ) 
                    : 
                    (
                        <div>            
                            <div>Customer Service -</div>
                            <div>customerservice@local03.com</div>
                            <a href="tel: 12129996016">12129996016</a>
                        </div>
                    )
                }
            </div>
            <div className="contact-info-element">
                <div className="contact-icon">
                    <img
                        src={process.env.PUBLIC_URL + '/assets/incoming-call.png'}
                        alt="contact-icon"
                    />
                </div>
            
                {isRTL ? 
                    (
                        <div>            
                            <div>למחלקת המכירות -</div>
                            <div>sales@omnisim.co.il</div>
                            <a href="tel: 97237222230">97237222230</a>                                        
                        </div>
                    ) 
                    : 
                    (
                        <div>            
                            <div>Sales -</div>
                            <div>sales@omnisim.co.il</div>
                            <a href="tel: 12129996210">12129996210</a>  
                        </div>
                    )
                }
            </div>
        </div>
    )
}

export default ContactInfo