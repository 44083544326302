
import React from 'react';
import classNames from 'classnames';
import './customFooter.css';


function CustomFooter(props) {
    const hasFooterStyle = props.footerStyle !== undefined;

	return (
	    <div className={classNames({ 'custom-footer': true, [`${props.footerStyle}`]: hasFooterStyle})}>
			<p>{props.children}</p>
		</div>
	);
}
export default CustomFooter;
