import React from 'react';
import apiConfig from '../../config/apiConfig';

const Manual = props => {
	const orderId = props.location.search.split('order=')[1];
	React.useEffect(() => {
		window.location = apiConfig.apiUrl + '/sims/manuals/?order_id=' + orderId;
	});
	return null;
};

export default Manual;
