import React from 'react';
import Rates from '../Rates/Rates';
import { Screen } from '../../components';

function CountryRates(props) {
	return (
		<Screen>
			<div className="main-screen-container">
				<Rates />
			</div>
		</Screen>
	);
}

export default CountryRates;
