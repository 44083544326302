import React from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import './snack.css';
function Snack(props) {
	const {
		title,
		text,
		match: {
			params: { locale }
		}
	} = props;
	const isRTL = locale === 'he';
	return (
		<div
			className={classNames({
				'snack-container': true,
				rtl: isRTL
			})}>
			<img
				className="snack icon pop"
				alt="speaker"
				src={process.env.PUBLIC_URL + '/assets/speaker.png'}
			/>
			<div className="snack-content">
				<span className="snack-title">{title}</span>
				<span>{text}</span>
			</div>
		</div>
	);
}
export default withRouter(Snack);
