//@flow
import React from 'react';
import './styles.css';
import './styles-rtl.css';
import classNames from 'classnames';
import DropDown from '../DropDown/DropDown';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { changeLocale } from '../../ducks/appState';
import { Redirect, Link } from 'react-router-dom';

const languages = [
	{ locale: 'he', name: 'עברית' },
	{ locale: 'en', name: 'English' }
];

var dropClass = "dropdown";

const findLocale = locale => {
	return languages.find(item => item.locale === locale);
};

function Header(props) {
        function test(){
		if(dropClass === "dropdown"){
			dropClass = "dropdown:hover";
		}
		else{
			dropClass = "dropdown";
		}
		console.log(dropClass)
	}
	const { i18n, t } = useTranslation();
	const { locale } = props.match.params;
	const isRTL = locale === 'he';
	function changeLanguage(lang) {
		props.changeLocale(lang.locale);
		props.history.push('/' + lang.locale + props.match.url.slice(3));
		i18n.changeLanguage(lang.locale);
	}
	React.useEffect(() => {
		i18n.changeLanguage(locale);
	}, []);

	function goHome() {
		props.history.push(props.match.url.slice(0, 3));
	}
        return (
		<header className="header">
			<div
				className={classNames({
					'header-container': true,
					rtl: isRTL
				})}>
				<div className="header-start">
					<div className="omni-logo" onClick={goHome}>
						<img
							src={process.env.PUBLIC_URL + '/assets/omnitelecom.png'}
							alt="Omnitelecom"
						/>
					</div>
				</div>

				<div className="header-end" onTouchStart = {test} onTouchEnd = {test}>
					<DropDown
						onChange={changeLanguage}
						getLabel={item => item && item.name}
						options={languages}
						value={findLocale(locale)}
                                               
					/>
					<div className="contact-us-link">
						<Link to={'/' + props.match.params.locale + '/contact-us'}>
							{t("Contact Us")}
						</Link>
					</div>
					<div className="local03-logo">
						<img
							src={process.env.PUBLIC_URL + '/assets/local03.png'}
							alt="Omnitelecom"
						/>
					</div>
				</div>
			</div>
		</header>
	);
}

export default connect(
	null,
	{ changeLocale }
)(withRouter(Header));
