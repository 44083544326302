//@flow
export function formatPrice(price: number, currency: string, locale: string) {
	if (!price) return;
	return `${getCurrencySymbol(currency || 'usd')}${price}`;
}

function getCurrencySymbol(currency: string) {
	const val = currency.toLowerCase();
	switch (val) {
		case 'usd':
			return '$';
		case 'ils':
			return '₪';
		default:
			return currency;
	}
}

export const formatRatesPrice = price => {
	if (!price) {
		return '';
	}
	if (isNaN(price)) {
		return '--';
	}
	return '$' + parseFloat(price).toFixed(3);
};
