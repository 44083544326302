import React from 'react';
import './order-form.css';
import { useTranslation, Trans } from 'react-i18next';
import TextField from '../TextField/TextField';
import { Field, reduxForm } from 'redux-form';
import validate from '../../utils/validator';
import { normalizeCCExpiration } from '../../utils/creditCardUtils';
import classNames from 'classnames';
function RenderField(field) {
	return (
		<TextField
			{...field}
			{...field.input}
			label={field.label}
			error={field.meta.error}
			touched={field.meta.touched ? 'touched' : ''}
		/>
	);
}
function OrderForm(props) {
	const { t } = useTranslation();
	const { isRTL } = props;

	return (
		<>
			<div>
				<div className="order-form-row-container">
					<Field
						name="billing.cardHoldersName"
						label={t("Card holder's name")}
						component={RenderField}
					/>
				</div>

				<div className="order-form-row-container">
					<div className="order-form-card-number">
						<Field
							maxLength={16}
							name="billing.creditCardNumber"
							label={t('Credit card number')}
							component={RenderField}
						/>
					</div>
				</div>
				<div className="order-form-row--responsive">
					<div className="order-form-row-container">
						<Field
							format={normalizeCCExpiration}
							maxLength={5}
							placeholder="MM/YY"
							name="billing.expiration"
							label={t('Expiration')}
							component={RenderField}
						/>
					</div>

					<div className="order-form-row-container">
						<div className="cvv-row-container">
							<label className="textfield-label">{t('cvv')}</label>
							<div id="cvv-hover">
								<img
									alt="CVV Explenation"
									src={require('../../assets/cvv.png')}
									className={classNames({ 'cvv-image': true, rtl: isRTL })}
								/>
								<div className="cvv-message-container">
									<Trans>
										CVV is the last 3 digits on the back of your credit card, or
										4 digits at the front of your American Express card
									</Trans>
								</div>
							</div>

							<Field maxLength={4} name="billing.cvv" component={RenderField} />
						</div>
					</div>
				</div>
			</div>
		</>
	);
}

export default reduxForm({
	form: 'order',
	destroyOnUnmount: false,
	validate: validate
})(OrderForm);
