import { createAction } from 'redux-starter-kit';

import { createReducer } from 'redux-starter-kit';
import apiConfig from '../config/apiConfig';

const initialState = {
	fetching: false,
	errors: null,
	data: []
};

const fetchPurchaseHistory = createAction('@@purchase_history/request');
const fetchPurchaseHistorySuccess = createAction('@@purchase_history/success');
const fetchPurchaseHistoryFailed = createAction('@@purchase_history/failed');
export const clearPurchaseHistory = createAction('@@purchase_history/clear');

const actions = {
	[fetchPurchaseHistory]: (state, action) => {
		state.fetching = true;
	},
	[fetchPurchaseHistorySuccess]: (state, action) => {
		state.fetching = false;
		state.data = action.payload;
	},
	[fetchPurchaseHistoryFailed]: (state, action) => {
		state.fetching = false;
		state.error = action.payload;
	},
	[clearPurchaseHistory]: (state, action) => {
		state.data = [];
	}
};
const purchaseHistoryReducer = createReducer(initialState, actions);
export default purchaseHistoryReducer;

export function getPurchaseHistory({ phoneNumber, startDate, endDate }) {
	return function(dispatch, getState) {
		dispatch(fetchPurchaseHistory());
		fetch(apiConfig.apiUrl + '/orders/history/', {
			method: 'POST',

			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + getState().auth.accessToken
			},
			body: JSON.stringify({
				start_date: startDate,
				end_date: endDate,
				sim_number: phoneNumber
			})
		})
			.then(async response => {
				if (!response.ok) {
					return dispatch(fetchPurchaseHistoryFailed('failed'));
				}
				const payload = await response.json();
				if (!payload.success) {
					return dispatch(fetchPurchaseHistoryFailed('failed'));
				}

				dispatch(fetchPurchaseHistorySuccess(payload.data));
			})
			.catch(e => {
				dispatch(fetchPurchaseHistoryFailed(String(e)));
			});
	};
}
