import React from 'react';
import './page.css';
import classNames from 'classnames';
import { Screen } from '../../components';

function Page(props) {
	const {
		match: {
			params: { locale }
		}
	} = props;
	const isRTL = locale === 'he';
	return (
		<Screen title={props.title}>
			<div className={classNames({ 'page-container': true, rtl: isRTL })}>
				<div className="page-content-container">
					<div className="page-title">
						<span>{props.title}</span>
					</div>
					<div
						className="page-content"
						dangerouslySetInnerHTML={{ __html: props.content }}
					/>
				</div>
			</div>
		</Screen>
	);
}

export default Page;
