import React from 'react';
import { withRouter, Switch, Route, Redirect } from 'react-router-dom';
import './rates.css';
import classNames from 'classnames';
import {AdminRatesTabBar, NavBar} from '../../components';

import Calls from './Calls';
import SMS from './SMS';
import Data from './Data';

function Rates(props) {
	const {
		match: {
			path,
			params: { locale },
			url
		}
	} = props;

	return (
		<div>
			<div
				className={classNames({
					'admin-rates-container': true,
					rtl: locale === 'he'
				})}>
				<AdminRatesTabBar />
				<div className="admin-rates-content-container">
					<Switch>
						<Route path={path + '/calls'} component={Calls} />
						<Route path={path + '/sms'} component={SMS} />
						<Route path={path + '/data'} component={Data} />
						<Route>
							<Redirect to={url + '/calls'} />
						</Route>
					</Switch>
				</div>
			</div>
			<NavBar />
		</div>
	);
}

export default withRouter(Rates);
