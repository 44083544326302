//@flow

import { createReducer } from 'redux-starter-kit';
import apiConfig from '../config/apiConfig';

function getDefaultLocale() {
	const heIndex = navigator.languages.findIndex(e => e === 'he');
	const enIndex = navigator.languages.findIndex(e => e === 'en');
	if (heIndex === -1) return 'en';
	return navigator.languages[Math.min(heIndex, enIndex)];
}

const initialState = {
	locale: getDefaultLocale(),
	session: {
		fetching: true,
		error: '',
		id: '',
		timestamp: ''
	}
};

const actions = {
	LOCALE_CHANGED: (state, action) => {
		state.locale = action.payload;
	},
	SESSION_ID_REQUESTED: (state, action) => {
		state.session.fetching = true;
	},
	SESSION_ID_SUCCEEDED: (state, action) => {
		state.session = {
			fetching: false,
			error: '',
			id: action.payload.session_id,
			timestamp: Date.now()
		};
	},
	SESSION_ID_FAILED: (state, action) => {
		state.session = {
			fetching: false,
			error: action.payload.error,
			id: '',
			timestamp: Date.now()
		};
	}
};

export default createReducer(initialState, actions);

export function changeLocale(locale: 'he' | 'en') {
	return {
		type: 'LOCALE_CHANGED',
		payload: locale
	};
}

export function getSessionId() {
	return {
		type: 'API',
		payload: {
			method: 'GET',
			resource: 'SESSION_ID',
			url: apiConfig.apiUrl + '/session/'
		}
	};
}
