import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import { getSimCards } from '../../ducks/simCards';
const SelectPhone = React.memo(props => {
	const { t } = useTranslation();
	React.useEffect(() => {
		props.getSimCards();
	}, []);

	return (
		<Select
			placeholder={t('Select')}
			theme={theme => {
				return {
					...theme,

					borderRadius: 0
				};
			}}
			className={'omni-select ' + props.className}
			options={props.simCards.map(item => ({
				label: item.sim_number,
				value: item.sim_number
			}))}
			{...props}
		/>
	);
});

const mapStateToProps = state => {
	return {
		loading: state.simCards.fetching,
		simCards: state.simCards.data
	};
};

const dispatchProps = {
	getSimCards
};

export default connect(
	mapStateToProps,
	dispatchProps
)(SelectPhone);
