import { signInSuccess } from '../auth';

const authMiddleWare = store => next => action => {
	if (action.type.split('/')[0] !== '@@auth') return next(action);
	if (action.type === signInSuccess.type) {
		sessionStorage.setItem('@omni:token', action.payload.accessToken);
		sessionStorage.setItem('@omni:refresh', action.payload.refreshToken);
	}
	if (action.type === '@@auth/SIGNED_OUT') {
		sessionStorage.clear();
	}
	return next(action);
};

export default authMiddleWare;
