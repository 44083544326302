import React from 'react';
import { Switch, Route, Redirect } from 'react-router-dom';
import Balance from './Balance';
import Purchase from './Purchase';
import Credits from './Credits';

const Status = props => {
	return (
		<Switch>
			<Route path={`${props.match.path}/purchase`} component={Purchase} />
			<Route path={props.match.path + '/credits'} component={Credits} />
			<Route path={props.match.path} exact component={Balance} />
			<Route>
				<Redirect to={props.match.url} />
			</Route>
		</Switch>
	);
};

export default Status;
