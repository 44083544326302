//@flow
import { createReducer } from 'redux-starter-kit';

const initialState = {
	byId: {},
	fetching: false,
	error: undefined,
	items: [],
	timestamp: undefined
};

const actions = {
	SIM_PLAN_TYPES_REQUESTED: (state, action) => {
		state.fetching = true;
	},
	SIM_PLAN_TYPES_SUCCEEDED: (state, action) => {
		const { data } = action.payload;
		state.fetching = false;
		state.byId = data.reduce(function(acc, cur) {
			return { ...acc, [cur]: cur };
		}, {});
		state.fetching = false;
		state.items = data;
		state.timestamp = Date.now();
	},
	SIM_PLAN_TYPES_FAILED: (state, action) => {
		state.fetching = false;
		state.error = action.payload;
	}
};

export function getSimPlanTypes() {
	/* const payload = require('./mocks/simPlanTypes.json');
	return {
		type: 'SIM_PLAN_TYPES_SUCCEEDED',
		payload
	}; */

	return {
		type: 'API',
		payload: {
			resource: 'SIM_PLAN_TYPES',
			url: 'http://140.82.59.71:7077/OmniApi/GetPlanType'
		}
	};
}

const simPlanTypesReducer = createReducer(initialState, actions);

export default simPlanTypesReducer;
