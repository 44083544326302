//@flow
import React from "react";
import "./package-card.css";
import classNames from "classnames";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { format, addDays, parse, isBefore, addYears, subDays } from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import { formatPrice } from "../../utils/priceFormatter";
import DatePicker from "react-datepicker";
import { enUS, he } from "date-fns/locale";
import "react-datepicker/dist/react-datepicker.css";
import {
  setSimPlanOrderItemDate,
  addSimPlanOrderItem,
  removeSimPlanOrderItem,
  simPlanOrderItemSelector,
} from "../../ducks/order";

export function mapCategoryToTheme(category) {
  const themeMap = {
    "israeli-sim": "blue",
    "global-sim": "red",
    "american-sim": "red",
    "esim-sim": "red",
    "european-sim": "blue",
  };

  return themeMap[category];
}

function PackageCard(props) {
  const { t } = useTranslation();
  const {
    match: {
      params: { locale, category },
    },
  } = props;
  const isRTL = locale === "he";
  const {
    simPlan: { subtitle, price, currency, duration },
  } = props;
  const displayDateFormat = locale === "he" ? "dd/MM/yyyy" : "MM/dd/yyyy";
  const coreDateFormat = "yyyy-MM-dd";
  function handleStartDateChange(event) {
    const startDate = event;
    if (isBefore(addDays(new Date(), -1), startDate)) {
      const endDate = addDays(startDate, duration - 1);
      props.setDate({
        startDate: format(startDate, coreDateFormat),
        endDate: format(endDate, coreDateFormat),
      });
    }
  }

  function handleEndDateChange(event) {
    const endDate = event;
    const startDate = addDays(endDate, -duration);
    props.setDate({
      startDate: format(startDate, coreDateFormat),
      endDate: format(endDate, coreDateFormat),
    });
  }

  function handleAdd() {
    props.add(duration);
  }

  const theme = mapCategoryToTheme(category);
  const arrowIcon =
    process.env.PUBLIC_URL +
    (isRTL ? "/assets/arrow-down-left.png" : "/assets/arrow-down-right.png");

  return (
    <div className="package-card-container">
      <div
        className={classNames({
          "package-card-content": true,
          rtl: isRTL,
        })}
      >
        <div className="package-card-info-container">
          <div className="package-card-info-titles">
            <div className="package-card-title">
              <span>{props.simPlan[`title_${locale}`]}</span>
            </div>
            {subtitle && (
              <div className="package-card-subtitle">
                <span>{subtitle}</span>
              </div>
            )}
          </div>

          <div className="package-card-extra-info">
            <ul>
              {props.simPlan[`description_${locale}`].map((item) => {
                return <li key={item}>{item}</li>;
              })}
            </ul>
          </div>
        </div>
        <div
          className={classNames({
            "package-card-price-container": true,
            blue: theme === "blue",
            red: theme === "red",
          })}
        >
          <div className="package-card-price">
            <span>{formatPrice(price, currency, locale)}</span>
          </div>
        </div>
      </div>
      <div
        className={classNames({
          "package-card-form-container": true,
          rtl: isRTL,
        })}
      >
        <div className="package-card-arrow-container">
          <img className="package-card-arrow" alt="arrow" src={arrowIcon} />
        </div>
        <div className="package-card-form-times">
          <div className="package-card-form-input-row">
            <span>{t("From")}:</span>
            <div className="package-card-form-input-container">
              <div className="package-card-form-input-icon-container start">
                <FontAwesomeIcon
                  icon="calendar-alt"
                  className="package-card-form-input-icon"
                />
              </div>

              <DatePicker
                renderCustomHeader={({
                  increaseMonth,
                  decreaseMonth,
                  changeMonth,
                  date,
                }) => {
                  const getChevron = (
                    action: "next" | "prev",
                    locale: "he" | "en"
                  ) => {
                    switch (action) {
                      case "next":
                        return locale === "he"
                          ? "chevron-left"
                          : "chevron-right";
                      case "prev":
                        return locale === "he"
                          ? "chevron-right"
                          : "chevron-left";
                      default:
                        return "";
                    }
                  };

                  return (
                    <div
                      style={{
                        margin: 10,
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "space-between",
                      }}
                    >
                      <FontAwesomeIcon
                        icon={getChevron("prev", locale)}
                        onClick={decreaseMonth}
                      />
                      <span>
                        {format(date, "MMMM yyyy", {
                          locale: locale === "he" ? he : enUS,
                        })}
                      </span>
                      <FontAwesomeIcon
                        icon={getChevron("next", locale)}
                        onClick={increaseMonth}
                      />
                    </div>
                  );
                }}
                locale={locale === "he" ? he : enUS}
                dateFormat={displayDateFormat}
                filterDate={(date) => isBefore(subDays(new Date(), 1), date)}
                min={new Date()}
                max={addYears(new Date(), 5)}
                onChange={handleStartDateChange}
                className="package-card-form-input"
                selected={parse(
                  props.order.startDate,
                  coreDateFormat,
                  new Date()
                )}
              />
            </div>
          </div>
          <div className="package-card-form-input-row">
            <span>{t("To")}:</span>
            <div className="package-card-form-input-container">
              <div className="package-card-form-input-icon-container start">
                <FontAwesomeIcon
                  icon="calendar-alt"
                  className="package-card-form-input-icon"
                />
              </div>
              <DatePicker
                readOnly
                locale={locale === "he" ? he : enUS}
                dateFormat={displayDateFormat}
                min={new Date()}
                max={addYears(new Date(), 5)}
                onChange={handleEndDateChange}
                className="package-card-form-input"
                selected={parse(
                  props.order.endDate,
                  coreDateFormat,
                  new Date()
                )}
              />
            </div>
          </div>
        </div>

        <div className="package-card-form-quantity">
          <div className="package-card-form-quantity-container">
            <span>{t("Qnt")}:</span>
          </div>

          <div className="package-card-form-input-row">
            <div className="package-card-form-input-container">
              <button
                className=" clickable package-card-form-input-icon-container start"
                disabled={props.order.quantity <= 0}
                onClick={props.remove}
              >
                <FontAwesomeIcon
                  icon="minus"
                  className="package-card-form-input-icon"
                />
              </button>
              <input
                disabled
                type="text"
                value={props.order.quantity}
                onChange={function () {}}
              />
              <button
                className="clickable package-card-form-input-icon-container end"
                onClick={handleAdd}
              >
                <FontAwesomeIcon
                  icon="plus"
                  className="package-card-form-input-icon"
                />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state, props) => {
  const { simPlanId } = props;
  const simPlan = state.simPlans.byId[simPlanId];
  return {
    order: simPlanOrderItemSelector(state, simPlanId),
    simPlan,
  };
};

const mapDispatchToProps = (dispatch, props) => {
  const { simPlanId } = props;
  return {
    setDate: (date) => dispatch(setSimPlanOrderItemDate(simPlanId, date)),
    add: (duration) => dispatch(addSimPlanOrderItem(simPlanId, duration)),
    remove: () => dispatch(removeSimPlanOrderItem(simPlanId)),
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(PackageCard));
