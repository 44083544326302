//@flow
import { combineReducers } from 'redux-starter-kit';
import appStateReducer from './appState';
import simPlanTypesReducer from './simPlanTypes';
import simPlansReducer from './simPlans';
import orderReducer from './order';
import shippingMethodsReducer from './shippingMethods';
import { reducer as formReducer } from 'redux-form';
import pagesReducer from './pages';
import authReducer from './auth';
import accountReducer from './account';
import countriesReducer from './countries';
import ratesReducer from './rates';
import simCardsReducer from './simCards';
import purchaseHistoryReducer from './purchaseHistory';
import contactUsReducer from './contactUs';

const rootReducer = combineReducers({
	appState: appStateReducer,
	simPlanTypes: simPlanTypesReducer,
	simPlans: simPlansReducer,
	order: orderReducer,
	shippingMethods: shippingMethodsReducer,
	form: formReducer,
	pages: pagesReducer,
	auth: authReducer,
	account: accountReducer,
	countries: countriesReducer,
	rates: ratesReducer,
	simCards: simCardsReducer,
	purchaseHistory: purchaseHistoryReducer,
	contactUs: contactUsReducer
});
export default rootReducer;
