import React from 'react';
import './status.css';
import { Redirect, Link } from 'react-router-dom';
import { useTranslation, Trans } from 'react-i18next';
import { Button, ProgressBar } from '../../components';
import classNames from 'classnames';
import { reduxForm, Field, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { validateCreditCard } from '../../utils/validator';
import { makeTopUp, clearTopUp } from '../../ducks/account';
import { normalizeCCExpiration } from '../../utils/creditCardUtils';
import validate from '../../utils/validator';
import TextField from '../../components/TextField/TextField';


let Purchase = props => {
	const { t } = useTranslation();

	const handleSubmit = props.handleSubmit(values => {
		props.makeTopUp(values);
	});

	function RenderField(field) {
		return (
			<TextField
			{...field}
			{...field.input}
			label={field.label}
			error={field.meta.error}
			touched={field.meta.touched ? 'touched' : ''}
		/>
		);
	}

	React.useEffect(() => {
		return props.clearTopUp;
	}, []);

	if (props.redirect) {
		return <Redirect to={props.match.url.replace('/purchase', '')} />;
	}

	return (
		<div
			className={classNames({
				'admin-status-container': true,
				rtl: props.match.params.locale === 'he'
			})}>
			<div className="admin-status-header">
				<h3>{t('Payment')}</h3>
			</div>
			<div>
				<table className="admin-status-table">
					<tbody>
						<tr>
							<td>
								<span>{t("Card holder's name")}</span>
							</td>
							<td>
								<Field
									tabIndex={1}
									autoComplete="cc-name"
									name="billing.cardHoldersName"
									component={RenderField}
									className="omni-input"
								/>
							</td>
						</tr>
						<tr>
							<td>
								<span>{t('Credit card number')}</span>
							</td>
							<td>
								<Field
									tabIndex={2}
									maxLength={16}
									name="billing.creditCardNumber"
									component={RenderField}
									className="omni-input"
								/>
							</td>
						</tr>
						<tr>
							<td>
								<span>{t('Expiration')}</span>
							</td>
							<td>
								<div className="admin-status-expiration-container">
									{/* <Field
										tabIndex={5}
										maxLength={2}
										placeholder={t('Year')}
										name="expYear"
										component="input"
										className="omni-input"
									/>
									<Field
										tabIndex={3}
										maxLength={2}
										placeholder={t('Month')}
										name="expMonth"
										component="input"
										className="omni-input"
									/> */}
									<Field
										format={normalizeCCExpiration}
										maxLength={5}
										placeholder="MM/YY"
										name="billing.expiration"
										component={RenderField}
										className="omni-input"
									/>									
								</div>
							</td>
						</tr>
						<tr>
							<td>
								<span>{t('cvv')}</span>
							</td>
							<td className="admin-status-expiration-container">
								<Field
									tabIndex={5}
									maxLength={4}
									name="billing.cvv"
									component={RenderField}
									className="omni-input"
								/>
							</td>
						</tr>
						<tr>
							<td>
								<div className="row">
									<Field
										tabIndex={6}
										name="acceptTerms"
										component="input"
										type="checkbox"
										className="omni-checkbox"
									/>
									<label>
										<Trans>
											I agree to the{' '}
											<Link
												target="_blank"
												to={
													'/' + props.match.params.locale + '/privacy-policy'
												}>
												privacy policy
											</Link>{' '}
											and the{' '}
											<Link
												target="_blank"
												to={
													'/' + props.match.params.locale + '/terms-of-service'
												}>
												terms of service
											</Link>
										</Trans>
									</label>
								</div>
							</td>
						</tr>
						<tr>
							<td colSpan={2}>
								{!props.loading ? (
									<Button
										tabIndex={7}
										disabled={!props.valid}
										type="submit"
										onClick={handleSubmit}>
										{t('submit')}
									</Button>
								) : (
									<div style={{ textAlign: 'center', width: '100%' }}>
										<ProgressBar className="admin-status-progress" />
									</div>
								)}
								{props.topUpErrors && (
									<center>
										<p className="submit-error">
											<Trans>
												Oops! Something went wrong.
											</Trans>
										</p>
									</center>
								)}
							</td>
						</tr>
					</tbody>
				</table>
			</div>
		</div>
	);
};

Purchase = reduxForm({
	form: 'topup',
	validate: validate
	// validate: values => {
	// 	const errors = {};
	// 	if (!values.acceptTerms) {
	// 		errors.acceptTerms = 'Required';
	// 	}
	// 	if (!validateCreditCard(values.cardNumber)) {
	// 		errors.cardNumber = 'Invalid';
	// 	}
	// 	return errors;
	// }
})(Purchase);

const selector = formValueSelector('topup');
const mapStateToProps = state => {
	const redirect =
		!selector(state, 'quantity') ||
		!state.account.selectedSimNumber ||
		state.account.topUp.success;
	return {
		redirect,
		loading: state.account.topUp.fetching,
		topUpErrors : state.account.topUp.errors
	};
};

const dispatchProps = {
	makeTopUp,
	clearTopUp
};
export default connect(
	mapStateToProps,
	dispatchProps
)(Purchase);
