import React from 'react';
import './shipping-info-form.css';
import { Input } from '..';
import { useTranslation } from 'react-i18next';
import SelectCountry from '../SelectCountry/SelectCountry';
import { formValues, Field, change } from 'redux-form';
import { connect } from 'react-redux';
import PickupOptions from '../PickupOptions/PickupOptions';
function renderInput(field) {

	const { t } = useTranslation();
	return (
		<div style={{ ...field.style, flexDirection: 'column', display: 'flex' }}>
			<Input
				{...field.input}
				style={{
					flex: 1,
					border:
						field.meta.error && field.meta.touched ? '1px solid red' : undefined
				}}
			/>
			{field.meta.error && field.meta.touched && (
				<span className="omni-input-error">
					{t('errors:' + field.meta.error)}
				</span>
			)}
		</div>
	);
}


function ShippingInfoForm(props) {
	const { t } = useTranslation();

	function resetShippingFields(){
		let fieldsToReset = [
			'shipping.street', 
			'shipping.houseNumber', 
			'shipping.apartment', 
			'shipping.city', 
			'shipping.country', 
			'shipping.state', 
			'shipping.postalCode'
		]
		for(let i = 0; i < fieldsToReset.length; i++) {
			props.updateField('order', fieldsToReset[i], '')
		}
	}

	const shippingMethod = props.shippingMethods[props.shippingMethod];
	if (shippingMethod && shippingMethod.isPickup) {
		resetShippingFields();
		console.log("test");
		return <PickupOptions shippingMethod={shippingMethod} />;
	}
	return (
		<div className="shipping-info-form">
			<div className="field">
				<label style={{ flex: 3 }}>{t('Street')}</label>
				<Field
					autocomplete="street-address"
					name="shipping.street"
					component={renderInput}
					style={{ flex: 9 }}
				/>
			</div>
			<div className="field">
				<label style={{ flex: 3 }}>{t('Number')}</label>
				<Field
					name="shipping.houseNumber"
					component={renderInput}
					style={{ flex: 9 }}
				/>
			</div>
			<div className="field">
				<label style={{ flex: 3 }}>{t('Apartment')}</label>
				<Field
					name="shipping.apartment"
					component={renderInput}
					style={{ flex: 9 }}
				/>
			</div>
			<div className="field">
				<label style={{ flex: 3 }}>{t('City')}</label>
				<Field
					autocomplete="address-level2"
					name="shipping.city"
					component={renderInput}
					style={{ flex: 9 }}
				/>
			</div>
			<div className="field">
				<label style={{ flex: 3 }}>{t('Country')}</label>
				<Field
					autocomplete="country"
					name="shipping.country"
					className="shipping-country-select"
					component={field => (
						<SelectCountry
							{...field}
							static
							value={field.input.value}
							onChange={c => field.input.onChange(c)}
						/>
					)}
				/>
			</div>
			<div className="field">
				<label style={{ flex: 3 }}>{t('State')}</label>
				<Field
					name="shipping.state"
					component={renderInput}
					style={{ flex: 9 }}
				/>
			</div>
			<div className="field">
				<label style={{ flex: 3 }}>{t('Postal code')}</label>
				<Field
					autocomplete="postal-code"
					name="shipping.postalCode"
					component={renderInput}
					style={{ flex: 9 }}
				/>
			</div>
		</div>
	);
}

const mapDispatchToProps = dispatch => ({
	updateField: (form, field, newValue) => {
		dispatch(change(form, field, newValue))
	}
})

const mapStateToProps = (state, props) => {
	return {
		shippingMethods: state.shippingMethods.byId
	};
};
export default connect(mapStateToProps, mapDispatchToProps)(
	formValues('shippingMethod')(ShippingInfoForm)
);
