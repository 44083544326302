export default store => next => action => {
	if (action.type !== 'SIM_PLAN_SET_SUCCEEDED') return next(action);

	const simPlanDataRequest = {
		type: 'API',
		payload: {
			url: 'http://140.82.59.71:7077/OmniApi/GetShoppingCartPageData',
			resource: 'SIM_PLANS'
		}
	};
	next(action);
	store.dispatch(simPlanDataRequest);
};
