import React from 'react';
import './status.css';
import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';
import { connect } from 'react-redux';
import { getBalance, selectSimNumber } from '../../ducks/account';
import { Input, Button, ProgressBar } from '../../components';
import SelectSimCard from '../../components/SelectSimCard/SelectSimCard';
function Balance(props) {
	const { t } = useTranslation();
	const handleSubmit = simCard => {
		props.getBalance(simCard.value);
	};

	React.useEffect(() => {
		if (props.simNumber) {
			props.getBalance(props.simNumber);
		}
	}, []);
	return (
		<div
			className={classNames({
				'admin-status-container': true,
				rtl: props.match.params.locale === 'he'
			})}>
			<div className="admin-status-header">
				<h3>{t('Change credit')}</h3>
			</div>
			<table>
				<tbody>
					<tr>
						<td>
							<Trans>Phone number</Trans>
						</td>
						<td>
							<SelectSimCard
								value={{ value: props.simNumber, label: props.simNumber }}
								onChange={c => {
									handleSubmit(c);
								}}
							/>
						</td>
					</tr>
					<tr>
						<td>
							<Trans>Credit balance</Trans>
						</td>
						<td>
							<Input
							style={{minWidth: 200}}
								value={props.balance ? '$' + props.balance : ''}
								readOnly
							/>
						</td>
					</tr>
				</tbody>
				<tfoot>
					<tr>
						<td colSpan={2}>
							{props.fetching && <ProgressBar />}
							<Button
								disabled={!props.simNumber}
								onClick={() =>
									props.history.push(props.location.pathname + '/credits')
								}>
								<Trans>Purchase Credit</Trans>
							</Button>
						</td>
					</tr>
				</tfoot>
			</table>
		</div>
	);
}

const mapStateToProps = state => {
	const simNumber = state.account.selectedSimNumber;
	const data = state.account.bySimNumber[simNumber] || {};
	return {
		simNumber,
		...data
	};
};

const dispatchProps = {
	getBalance,
	selectSimNumber
};

export default connect(
	mapStateToProps,
	dispatchProps
)(Balance);
