import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import common_he from './translations/he/common.json';
import contact_he from './translations/he/contact.json';
import titles_he from './translations/he/titles.json';
import titles_en from './translations/en/titles.json';
import footers_he from './translations/he/footers.json';
import footers_en from './translations/en/footers.json';
import countries_allowed from './ducks/mocks/countriesAllowed.json';
import countries_allowed_data_only from './ducks/mocks/countriesAllowedDataOnly.json';

const resources = {
	he: {
		contact: contact_he,
		titles: titles_he,
		common: common_he,
		footers: footers_he,
		countriesAllowed: countries_allowed,
		countriesAllowedDataOnly: countries_allowed_data_only,
		meta: {
			description:
				'הזמינו עכשיו כרטיסי סים אונליין לנסיעה הבאה שלכם. אומניטלקום מציעה כרטיסי SIM למגוון יעדים בחו"ל במחירים נמוכים במיוחד.'
		},
		errors: {
			'Please complete private name and surname': 'יש למלא שם פרטי ושם משפחה',
			'Please complete a valid phone number': 'יש למלא מספר טלפון תקין',
			'Please complete a valid email': 'יש למלא אימייל תקין',
			'Please complete a valid street': 'יש למלא שם רחוב עבור המשלוח',
			'Please complete a valid street number': 'יש למלא מספר רחוב עבור המשלוח',
			'Please complete a valid aprtment number':
				'יש למלא מספר דירה עבור המשלוח',
			'Please complete a city name': 'יש למלא שם עיר עבור המשלוח',
			'Please complete a country name': 'יש למלא ארץ עבור המשלוח',
			'Please complete a state name': 'יש למלא מדינה עבור המשלוח',
			'Please complete a valid postal code': 'יש למלא מיקוד תקין עבור המשלוח',
			'Please complete the full card number': 'יש לוודא הקלדת מספר הכרטיס המלא',
			'Please fill in expiration date':
				'יש למלא את השדות לתוקף הכרטיס: חודש ושנה',
			'Please fill in CVV nubmer': 'לא הוקלד מספר CVV',
			'Please accept policy and terms of service':
				'יש לאשר את תנאי הפרטיות והתקנון',
			'Enter a valid first name and surname': 'יש להזין שם פרטי ושם משפחה',
			'Enter a valid email address':'יש להזין אימייל תקין',
			'Enter a valid email address (For excample omni@gmail.com)': 'יש להזין אימייל תקין (לדוגמא: omni@gmail.com)',
			'Enter a valid phone number': 'יש להזין מספר טלפון תקין',
			'Enter a valid street': 'יש להזין שם רחוב עבור המשלוח',
			'Enter a valid street number': 'יש להזין מספר רחוב עבור המשלוח',
			'Enter a valid city name': 'יש למלא שם עיר עבור המשלוח',
			'Enter a valid postal code': 'יש למלא מיקוד תקין עבור המשלוח',
			'No more than 80 characters': 'עד 80 תווים',
			'No more than 120 characters': 'עד 120 תווים',
			'No more than 17 characters': 'עד 17 תווים',
			'No more than 50 characters': 'עד 50 תווים',
			'No more than 10 characters': 'עד 10 תווים',
			'No more than 30000 characters': 'עד 30000 תווים'


		}
	},
	en: {
		meta: {
			description:
				'Order your SIM card online. Omnitelecom offers quality sim cards with unlimited data and calls. SIM cards for Israel, USA, Canada and global sim cards.'
		},
		titles: titles_en,
		footers: footers_en
	}
};

function getInitialLang() {
	return window.location.pathname.slice(1, 3);
}
i18n
	.use(initReactI18next) // passes i18n down to react-i18next
	.init({
		debug: false,
		resources,
		lng: getInitialLang(),
		defaultNS: 'common',
		keySeparator: false, // we do not use keys in form messages.welcome

		interpolation: {
			escapeValue: false // react already safes from xss
		}
	});

export default i18n;
