import React from 'react';
import './post-purchase.css';
import { Link } from 'react-router-dom';
import {
	Screen,
	ScreenCard,
	ScreenCardSection,
	OrderSummaryItem
} from '../../components';
import classNames from 'classnames';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { getShippingMethods } from '../../ducks/shippingMethods';
import {clearOrder, orderTotalPriceSelector} from '../../ducks/order';
import PickupOptions from '../../components/PickupOptions/PickupOptions';
import {formatPrice} from "../../utils/priceFormatter";

const REGULAR_SHIPPING = 3;
function PostPurchase(props) {
	React.useEffect(() => {
		return () => props.clearOrder();
	}, []);

	const { t } = useTranslation();
	const {
		match: {
			params: { locale }
		}
	} = props;

	const isRTL = locale === 'he';
	if (props.redirect) {
		return <Redirect to="/" />;
	}
	return (
		<Screen>
			<div
				className={classNames({
					'post-purchase-container': true,
					rtl: isRTL
				})}>
				<ScreenCard title={<Title />}>
					<ScreenCardSection title={t('Order summary')}>
						<div className="order-summary-container">
							<div className="order-summary-section">
								<span>
									{t('Order number')}: {props.transactionId}
								</span>
								<span>
									{t('Name')}: {props.customerName}
								</span>
								<span>
									{t('Phone number')}: {props.phoneNumber}
								</span>
							</div>
							{props.isPickup ? (
								<div style={{ marginTop: 10 }}>
									<PickupOptions shippingMethod={props.shippingMethod} />
								</div>
							) : (
								<div className="order-summary-section">
									<span>
										{t('Shipping address')}:{' ' + props.shippingAddress}
									</span>
									<span>
										{t('Shipping method')}:{' '}
										{props.shippingMethod.title[props.match.params.locale]}
									</span>
								</div>
							)}
							<hr />
							<div className={'order-row no-border'}>
								<span className="order-total-cell-title">{t('Total price')}</span>
								<span className="order-total-cell">
								{formatPrice(props.totalPrice)}
							</span>
							</div>
						</div>
						<hr />
						<div className="order-summary-items">
							<ul className={classNames({ rtl: isRTL })}>
								{props.orderItems.map(item => {
									return (
										<li key={item.planId}>
											<OrderSummaryItem editable={false} {...item} />
										</li>
									);
								})}
							</ul>
						</div>
					</ScreenCardSection>
					<br />
					<ScreenCardSection title={t('SIM installation manual')}>
						<br />
						<span>
							{t(
								'Please save the manual and use it when arriving to your destination abroad'
							)}
							.
						</span>
						{props.manuals.map(item => {
							return (
								<Link
									key={item.orderId}
									to={'/manuals?order=' + item.orderId}
									target="_blank"
									rel="noopener noreferrer">
									<div className="clickable column download-manual">
										<span>{t('Click on the image below to download')}</span>
										<img src={require('../../assets/pdf.png')} alt="pdf" />
									</div>
								</Link>
							);
						})}

						<div className="post-purchase-contact-form-container">
							<div>
								<img
									src={process.env.PUBLIC_URL + '/assets/red-star.png'}
									alt="star"
								/>
							</div>
							<div className="post-purchase-contact-form-text">
								<span>
									{t('Contact us to change purchase/package information')}:
								</span>
								<div>
									<span>{t('contact:Email')}:</span>
									<span>customerservice@local03.com</span>
								</div>
								<div>
									<span>{t('contact:Phone')}:</span>
									<span style={{ whiteSpace: 'nowrap' }} dir="ltr">
										+972-3-7222222
									</span>
									,{' '}
									<span dir="ltr" style={{ whiteSpace: 'nowrap' }}>
										+1-212-9996029
									</span>
								</div>
							</div>
						</div>
					</ScreenCardSection>
				</ScreenCard>
			</div>
		</Screen>
	);
}

function Title() {
	const { t } = useTranslation();
	return (
		<div className="thank-you-container">
			<img src={require('../../assets/present.gif')} alt="present" />
			<div className="thank-you-text">
				<span>{t('Thank you')}</span>
				<span>{t('Purchase completed successfuly')} :)</span>
			</div>
		</div>
	);
}

const mapStateToProps = (state, props) => {
	const orderData = state.order.orderData;

	try {
		return {
			transactionId: orderData.transaction_id,
			customerName: orderData.first_name + ' ' + orderData.last_name,
			phoneNumber: orderData.phone_number,
			shippingAddress: `${orderData.street} ${orderData.house_number}${
				orderData.apartment ? ' - ' + orderData.apartment : ''
			}, ${orderData.postal_code} , ${orderData.city}${
				orderData.state ? ', ' + orderData.state : ''
			} ${orderData.country}`,
			shippingMethod: state.shippingMethods.byId[orderData.shipping_method],
			isPickup: orderData.shipping_method < REGULAR_SHIPPING,
			totalPrice: orderTotalPriceSelector(state),

			orderItems: Object.keys(state.order.items).map(planId => {
				return {
					planId,
					title:
						state.simPlans.byId[planId][
							props.match.params.locale === 'he' ? 'title_he' : 'title_en'
						],
					...state.order.items[planId]
				};
			}),
			manuals: orderData.items
				? orderData.items.map(item => ({ orderId: item.OrderId }))
				: [],
			redirect: !orderData.transaction_id
		};
	} catch (e) {
		console.log(e);
		return {
			redirect: true
		};
	}
};
export default connect(
	mapStateToProps,
	{ getShippingMethods, clearOrder }
)(PostPurchase);
