import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import SelectCarrier from '../../components/SelectCarrier/SelectCarrier';
import SelectCountry from '../../components/SelectCountry/SelectCountry';
import { getRates, hashRate } from '../../ducks/rates';
import { ProgressBar } from '../../components';
import { connect } from 'react-redux';
import { formatRatesPrice } from '../../utils/priceFormatter';

const reducer = (state, action) => {
	switch (action.type) {
		case 'source_country_selected': {
			return { ...state, source: { ...state.source, country: action.payload } };
		}
		case 'source_carrier_selected': {
			return { ...state, source: { ...state.source, carrier: action.payload } };
		}
		case 'destination_country_selected': {
			return {
				...state,
				destination: { ...state.destination, country: action.payload }
			};
		}
		case 'destination_carrier_selected': {
			return {
				...state,
				destination: { ...state.destination, carrier: action.payload }
			};
		}
		default:
			return state;
	}
};

const initialState = {
	source: {
		country: null,
		carrier: null
	},
	destination: {
		country: null,
		carrier: null
	}
};

const Calls = props => {
	const { t } = useTranslation();

	const [state, dispatch] = React.useReducer(reducer, initialState);

	const handleSubmit = () => {
		if (!state.source.carrier.value || !state.destination.country.label) {
			return;
		}
		props.getRates({
			location: state.source.carrier.value,
			destination: state.destination.country.label
		});
	};

	const rate =
		state.source.carrier && state.destination.country
			? props.rates[
					hashRate(state.source.carrier.value, state.destination.country.label)
			  ]
			: null;
	const cost = rate ? formatRatesPrice(rate.CostSMS) : '';
	return (
		<div>
			<div className="admin-rates-card">
				<div className="admin-rates-card-header">
					<h4>{t('Source')}</h4>
					<img src={process.env.PUBLIC_URL + '/assets/note.png'} alt="note" />
					<span>?</span>
				</div>
				<div className="admin-rates-card-body">
					<div className="admin-rates-action-row">
						<div className="row">
							<label>{t('Country')}</label>
							<SelectCountry
								value={state.source.country}
								onChange={c =>
									dispatch({ type: 'source_country_selected', payload: c })
								}
							/>
						</div>
						<div className="row">
							<label>{t('Carrier')}</label>
							<SelectCarrier
								countryIsoCode={
									state.source.country && state.source.country.value
								}
								value={state.source.carrier}
								onChange={c =>
									dispatch({ type: 'source_carrier_selected', payload: c })
								}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="admin-rates-card">
				<div className="admin-rates-card-header">
					<h4>{t('Destination Country')}</h4>
					<img src={process.env.PUBLIC_URL + '/assets/note.png'} alt="note" />
					<span>?</span>
				</div>
				<div className="admin-rates-card-body">
					<div className="admin-rates-action-row">
						<div className="row">
							<label>{t('Country')}</label>
							<SelectCountry
								value={state.destination.country}
								onChange={c =>
									dispatch({ type: 'destination_country_selected', payload: c })
								}
							/>
						</div>
						{/* 	<div className="row">
							<label>{t('Carrier')}</label>
							<SelectCarrier
								countryIsoCode={
									state.destination.country && state.destination.country.value
								}
								value={state.destination.carrier}
								onChange={c =>
									dispatch({ type: 'destination_carrier_selected', payload: c })
								}
							/>
						</div> */}
						<div>
							<button
								className="admin-rates-button"
								type="button"
								onClick={handleSubmit}>
								{t('Calculate')}
							</button>
						</div>
					</div>
				</div>
			</div>
			{props.loading && <ProgressBar />}
			{cost && (
				<div className="row">
					<div className="row admin-rates-call-info">
						<img
							src={process.env.PUBLIC_URL + '/assets/sms-color.png'}
							alt="incoming"
						/>
						<span>
							<Trans price={cost}>
								75 characters SMS cost <b>{{ cost }}</b>
							</Trans>
						</span>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		loading: state.rates.fetching,
		rates: state.rates.byLocationAndDestination
	};
};

const dispatchProps = {
	getRates
};
export default connect(
	mapStateToProps,
	dispatchProps
)(Calls);
