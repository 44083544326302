//@flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import './screen-title.css';
import { mapCategoryToTheme } from '../PackageCard/PackageCard';

const themes = {
	red: '#ee3823',
	blue: '#009cff'
};

function ScreenTitle(props) {
	const { locale, category } = props.match.params;
	const isRTL = locale === 'he';

	function renderChildren() {
		if (typeof props.children === 'string') {
			return <span>{props.children}</span>;
		} else if (typeof props.children === 'function') {
			return props.children();
		} else {
			return props.children;
		}
	}
	const themeColor =
		themes[mapCategoryToTheme(category)] ||
		themes[props.theme] ||
		props.theme ||
		themes['blue'];
	return (
		<div
			className="screen-title-container"
			style={{
				backgroundColor: themeColor,
				backgroundRepeat: 'no-repeat',
				backgroundPosition: isRTL ? 'right' : 'left',
				backgroundImage: `url(${process.env.PUBLIC_URL +
					'/assets/globe-fluid.png'}), linear-gradient(${
					isRTL ? '90deg' : '270deg'
				}, ${themeColor}, ${themeColor}, rgba(255,255,255,0.5))`
			}}>
			<div className={classNames({ 'screen-title-content': true, rtl: isRTL })}>
				{renderChildren()}
			</div>
		</div>
	);
}
export default withRouter(ScreenTitle);
