// import React from 'react';
import React, { useRef } from 'react';
import { useState } from 'react';
import {
	Screen,
	NavBar,
	OrderSummaryItem,
	OrderForm,
	ScreenCard,
	ProgressBar,
	ScreenCardSection,
	Button
} from '../../components';
import { connect } from 'react-redux';
import classNames from 'classnames';
import { useTranslation, Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { formValueSelector, reduxForm, Field } from 'redux-form';
import './payment.css';
import { orderTotalPriceSelector, createOrder } from '../../ducks/order';
import validate from '../../utils/validator';
import { formatPrice } from '../../utils/priceFormatter';
import ReCAPTCHA from 'react-google-recaptcha';

const REGULAR_SHIPMENT = 3;
let Payment = props => {

	const captchaRef = useRef(null)
	const [isCaptchaVerified, setIsCaptchaVerified] = useState(false);

	/* 	React.useEffect(() => {
		if (!props.isRouteAllowed) {
			props.history.replace('/');
		}
	}, []); */
	React.useEffect(() => {
		if (props.submitSuccess) {
			props.history.push(props.match.url.replace('payment', 'thank-you'));
		}
	}, [props.submitSuccess]);

	function handleRecaptchaChange(token) {
		setIsCaptchaVerified(true);
	}

	function handleSubmit(data) {
		// console.log(captchaRef.current.getValue())
		// console.log(data);
		props.createOrder({
			...data,
			shipping:
				data.shippingMethod >= REGULAR_SHIPMENT
					? { ...data.shipping, country: data.shipping.country.value }
					: data.shipping,
			captchaRef: captchaRef.current.getValue()
		});
	}
	const { t } = useTranslation();
	const {
		match: {
			params: { locale, category }
		}
	} = props;
	const isRTL = locale === 'he';
	return (
		<Screen title={t('titles:' + category)}>
			<div
				className={classNames({
					'order-screen-container': true,
					rtl: isRTL
				})}>
				<div className="order-screen-heading">
					<span>{t('Stage {{stage}} of 3', { stage: 3 })}</span>
				</div>
				<ScreenCard title={t('Order summary and payment')}>
					<ScreenCardSection title={t('Order Summary') + ':'}>
						<ul className={classNames({ rtl: isRTL })}>
							{props.items.map(function(item) {
								return (
									<OrderSummaryItem
										duration={item.duration}
										simPlanId={item.plan_id}
										title={item.title}
										quantity={item.quantity}
										startDate={item.startDate}
										endDate={item.endDate}
										locale={locale}
									/>
								);
							})}
						</ul>
						{!props.shipping.isPickup && <div className="order-row">
							<span>
								{t('Delivery')}: {props.shipping.name}
							</span>
							<span>{formatPrice(props.shipping.price) || t('Free')}</span>
						</div>}
						<div className={props.shipping.isPickup ? 'order-row no-border' : "order-row total"}>
							<span className="order-total-cell-title">{t('Total price')}</span>
							<span className="order-total-cell">
								{formatPrice(props.totalPrice)}
							</span>
						</div>
						{/*<div className="order-change">*/}
						{/*	<img*/}
						{/*		src={process.env.PUBLIC_URL + '/assets/red-star.png'}*/}
						{/*		alt="bang"*/}
						{/*	/>*/}
						{/*	<Trans>*/}
						{/*		For changing order details{' '}*/}
						{/*		<Link to={props.match.url.replace('payment', 'order')}>*/}
						{/*			go back to previous pages*/}
						{/*		</Link>*/}
						{/*	</Trans>*/}
						{/*</div>*/}
					</ScreenCardSection>
					<ScreenCardSection title={t('Payment') + ':'}>
						<OrderForm isRTL={isRTL} />
						<div className="order-form-row-container">
							<ReCAPTCHA
								sitekey={process.env.REACT_APP_SITE_KEY}
								ref={captchaRef}
								onChange={handleRecaptchaChange}
							/>
							<Field
								name="acceptTerms"
								component={field => {
									return (
										<div
											className="order-form-terms"
											onClick={() => {
												field.input.onChange(!field.input.value);
											}}>
											<input
												checked={field.input.value}
												type="checkbox"
												onChange={() => null}
												className="order-form-checkbox"
											/>
											<span className="privacy-link">
												<Trans>
													I agree to the{' '}
													<Link
														to={'/' + locale + '/privacy-policy'}
														target="_blank">
														privacy policy
													</Link>{' '}
													and the{' '}
													<Link
														to={'/' + locale + '/terms-of-service'}
														target="_blank">
														terms of service
													</Link>
												</Trans>
											</span>
										</div>
									);
								}}
							/>
						</div>

						<div className="order-form-row-container">
							{props.errors && (
								<div className="order-form-errors">{String(props.errors)}</div>
							)}
						</div>
						<div className="order-form-button">
							{props.isSubmitting ? (
								<ProgressBar />
							) : (
								<Button
									variant="success"
									disabled={!props.valid || !isCaptchaVerified}
									type="button"
									onClick={props.handleSubmit(handleSubmit)}>
									{t('submit')}
								</Button>
							)}
						</div>
						{props.submitError && !props.submitting && (
							<center>
								<p className="submit-error">
									<Trans>
										Oops! Something went wrong.
									</Trans>
									{isRTL ? 
										(
											<div>
												אנו ממליצים לך להמשיך את תהליך ההזמנה מול נציגינו במחלקת המכירות באחת הדרכים הבאות:
												<br />
												טלפון: <a href="tel: 97237222230"> +97237222230</a>
												<br />
												מייל: sales@omnisim.co.il
											</div>
										) 
										: 
										(
											<div>
												For SIM orders, please give us a call at
												<br />
												<a href="tel: 12129996120">1-212-999-6120 </a>
												or contact us at: sales@omnisim.co.il
											</div>
										)
									}
								</p>
							</center>
						)}
					</ScreenCardSection>
				</ScreenCard>

				<NavBar prev={props.match.url.replace('payment', 'order')} />
			</div>
		</Screen>
	);
};

const selector = formValueSelector('order');
const mapStateToProps = state => {
	return {
		submitSuccess: state.order.orderData.transaction_id,
		submitError: state.order.errors,
		isSubmitting: state.order.fetching,
		isRouteAllowed: state.form && state.form.order,
		totalPrice: orderTotalPriceSelector(state),
		shipping:
			state.shippingMethods.byId[selector(state, 'shippingMethod')] || {},
		items: Object.keys(state.order.items)
			.map(key => ({
				...state.order.items[key],
				...state.simPlans.byId[key],
				title: state.simPlans.byId[key]['title_' + state.appState.locale]
			}))
			.filter(item => item.quantity > 0)
	};
};

Payment = reduxForm({
	form: 'order',
	validate: validate,
	destroyOnUnmount: false
})(Payment);

Payment = connect(
	mapStateToProps,
	{ createOrder }
)(Payment);

export default Payment;
