//@flow
import { configureStore as cs, getDefaultMiddleware } from 'redux-starter-kit';
import rootReducer from './rootReducer';
import apiMiddleware from './middleware/api';
import authMiddleWare from './middleware/auth';
import localeMiddleware from './middleware/localeMiddleware';
import simPlanSelector from './middleware/simPlanSelector';
import analyticsMiddleware from './middleware/analytics';

function configureStore() {
	const store = cs({
		reducer: rootReducer,
		devTools: process.env.NODE_ENV !== "production",
		middleware: [
			...getDefaultMiddleware(),
			authMiddleWare,
			localeMiddleware,
			apiMiddleware,
			simPlanSelector,
			analyticsMiddleware
		]
	});

	if (process.env.NODE_ENV !== 'production' && module.hot) {
		module.hot.accept('./rootReducer', () => store.replaceReducer(rootReducer));
	}

	return store;
}

export default configureStore;
