//@flow
import React from 'react';
import './input.css';

function Input(props): React.ElementType {
	return (
		<input {...props} className={'omni-input ' + (props.className || '')} />
	);
}

export default Input;
