import React from 'react';
import Select from 'react-select';
import { useTranslation } from 'react-i18next';
import apiConfig from '../../config/apiConfig';

const SelectCarrier = React.memo(props => {
	const [carrier, setCarrier] = React.useState([]);
	const { t } = useTranslation();
	React.useEffect(() => {
		const abortController = new AbortController();
		const signal = abortController.signal;
		if (!props.countryIsoCode) {
			return;
		}
		fetch(
			apiConfig.apiUrl + '/sims/carriers/?iso_code=' + props.countryIsoCode,
			{ signal }
		).then(async res => {
			const payload = await res.json();
			setCarrier(payload.data);
		});
		return () => abortController.abort();
	}, [props.countryIsoCode]);

	return (
		<Select
			placeholder={t('Select')}
			theme={theme => {
				return {
					...theme,

					borderRadius: 0
				};
			}}
			className="admin-rates-select"
			options={carrier
				.filter(item => (props.isSource ? item.is_source : item))
				.map(item => ({
					label: item.name,
					value: item.id
				}))}
			{...props}
		/>
	);
});

export default SelectCarrier;
