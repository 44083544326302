import { createReducer } from 'redux-starter-kit';
import apiConfig from '../config/apiConfig';

const initialState = {
	fetching: false,
	errors: null,
	data: []
};
const actions = {
	'@@simCards/FETCH_REQUEST': (state, action) => ({
		...state,
		fetching: true
	}),
	'@@simCards/FETCH_SUCCEEDED': (state, action) => {
		state.fetching = false;
		state.data = action.payload;
	},
	'@@simCards/FETCH_FAILED': (state, action) => {
		state.fetching = false;
		state.error = action.payload;
	}
};
const simCardsReducer = createReducer(initialState, actions);
export default simCardsReducer;

export function getSimCards() {
	return function(dispatch, getState) {
		if (getState().simCards.data.length) {
			return;
		}
		dispatch({ type: '@@simCards/FETCH_REQUEST' });
		fetch(apiConfig.apiUrl + '/sims/', {
			headers: {
				Authorization: 'Bearer ' + getState().auth.accessToken
			}
		})
			.then(async response => {
				const payload = await response.json();
				if (!payload.success) {
					dispatch({
						type: '@@simCards/FETCH_FAILED',
						payload: payload.error
					});
				}
				dispatch({
					type: '@@simCards/FETCH_SUCCEEDED',
					payload: payload.data
				});
			})
			.catch(e => {
				dispatch({ type: '@@simCards/FETCH_FAILED', payload: String(e) });
			});
	};
}
