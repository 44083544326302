//@flow

type ApiConfig = {
	host: String,
	apiUrl: String
};

const apiConfig: ApiConfig = {
	host: process.env.REACT_APP_HOST_URL || 'http://www.omnisim.com',
	apiUrl: process.env.REACT_APP_API_URL || 'http://www.omnisim.com/api'
};

export default apiConfig;
