import React from 'react';
import './order-summary-item.css';
import {useTranslation} from 'react-i18next';
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {
    addSimPlanOrderItem,
    removeSimPlanOrderItem,
    setSimPlanOrderItemDate,
   // simPlanOrderItemSelector
} from "../../ducks/order";
import {connect} from "react-redux";
//import {withRouter} from "react-router-dom";
import DatePicker from "react-datepicker";
import {enUS, he} from "date-fns/locale";
import {addYears, parse, isBefore, addDays, format, subDays} from "date-fns";

function OrderSummaryItem({title, quantity, duration, startDate, endDate, add, remove, simPlanId, setDate, locale, editable = true}) {
    const {t} = useTranslation();
    const displayDateFormat = locale === 'he' ? 'dd/MM/yyyy' : 'MM/dd/yyyy';
    const coreDateFormat = 'yyyy-MM-dd';

    return (
        <>
            <table className="order-summary-item-table">
                <thead>
                <tr>
                    <th colSpan={2}>{title}</th>
                </tr>
                </thead>
                <tbody>
                {editable ? <tr>
                    <td>{t('Quantity')}</td>
                    <div className="package-card-form-times">
                        <div className="package-card-form-input-row">
                            <div className="package-card-form-input-container">
                                <button
                                    className=" clickable package-card-form-input-icon-container start"
                                    onClick={remove}>
                                    <FontAwesomeIcon
                                        icon="minus"
                                        className="package-card-form-input-icon"
                                    />
                                </button>
                                <input
                                    disabled
                                    type="text"
                                    value={quantity}
                                    onChange={function () {
                                    }}
                                />
                                <button
                                    className="clickable package-card-form-input-icon-container end"
                                    onClick={handleAdd}>
                                    <FontAwesomeIcon
                                        icon="plus"
                                        className="package-card-form-input-icon"
                                    />
                                </button>
                            </div>
                        </div>
                    </div>
                </tr> : <tr>
                    <td>{t('Quantity')}</td>
                    <td>{quantity}</td>
                </tr>
                }

                {editable ? <tr>
                    <td>{t('Start date')}</td>
                    <div className="package-card-form-times">
                        <div className="package-card-form-input-row">
                            <div className="package-card-form-input-container">
                                <div className="package-card-form-input-icon-container start">
                                    <FontAwesomeIcon
                                        icon="calendar-alt"
                                        className="package-card-form-input-icon"
                                    />
                                </div>

                                <DatePicker
                                    renderCustomHeader={({
                                                             increaseMonth,
                                                             decreaseMonth,
                                                             changeMonth,
                                                             date
                                                         }) => {
                                        const getChevron = (
                                            action: 'next' | 'prev',
                                            locale: 'he' | 'en'
                                        ) => {
                                            switch (action) {
                                                case 'next':
                                                    return locale === 'he'
                                                        ? 'chevron-left'
                                                        : 'chevron-right';
                                                case 'prev':
                                                    return locale === 'he'
                                                        ? 'chevron-right'
                                                        : 'chevron-left';
                                                default:
                                                    return '';
                                            }
                                        };

                                        return (
                                            <div
                                                style={{
                                                    margin: 10,
                                                    display: 'flex',
                                                    flexDirection: 'row',
                                                    justifyContent: 'space-between'
                                                }}>
                                                <FontAwesomeIcon
                                                    icon={getChevron('prev', locale)}
                                                    onClick={decreaseMonth}
                                                />
                                                <span>
												{format(date, 'MMMM yyyy', {
                                                    locale: locale === 'he' ? he : enUS
                                                })}
											</span>
                                                <FontAwesomeIcon
                                                    icon={getChevron('next', locale)}
                                                    onClick={increaseMonth}
                                                />
                                            </div>
                                        );
                                    }}
                                    locale={locale === 'he' ? he : enUS}
                                    dateFormat={displayDateFormat}
                                    filterDate={date => isBefore(subDays(new Date(), 1), date)}
                                    min={new Date()}
                                    max={addYears(new Date(), 5)}
                                    onChange={handleStartDateChange}
                                    className="package-card-form-input"
                                    selected={parse(
                                        startDate,
                                        coreDateFormat,
                                        new Date()
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </tr> : <tr>
                    <td>{t('Start date')}</td>
                    <td>{startDate}</td>
                </tr>
                }

                {editable ? <tr>
                    <td>{t('End date')}</td>
                    <div className="package-card-form-times">
                        <div className="package-card-form-input-row">
                            <div className="package-card-form-input-container">
                                <div className="package-card-form-input-icon-container start">
                                    <FontAwesomeIcon
                                        icon="calendar-alt"
                                        className="package-card-form-input-icon"
                                    />
                                </div>
                                <DatePicker
                                    readOnly
                                    locale={locale === 'he' ? he : enUS}
                                    dateFormat={displayDateFormat}
                                    min={new Date()}
                                    max={addYears(new Date(), 5)}
                                    onChange={handleEndDateChange}
                                    className="package-card-form-input"
                                    selected={parse(
                                        endDate,
                                        coreDateFormat,
                                        new Date()
                                    )}
                                />
                            </div>
                        </div>
                    </div>
                </tr> : <tr>
                    <td>{t('End date')}</td>
                    <td>{endDate}</td>
                </tr>
                }

                </tbody>
            </table>
            <hr/>
        </>
    );

    function handleAdd() {
        add(quantity);
    }

    function handleStartDateChange(event) {
        const startDate = event;
        if (isBefore(addDays(new Date(), -1), startDate)) {
            const endDate = addDays(startDate, duration - 1);
            setDate({
                startDate: format(startDate, coreDateFormat),
                endDate: format(endDate, coreDateFormat)
            });
        }
    }

    function handleEndDateChange(event) {
        const endDate = event;
        const startDate = addDays(endDate, -duration);
        setDate({
            startDate: format(startDate, coreDateFormat),
            endDate: format(endDate, coreDateFormat)
        });
    }

}

const mapStateToProps = (state, props) => {
    // const { simPlanId } = props;
    // const simPlan = state.simPlans.byId[simPlanId];
    // return {
    // 	order: simPlanOrderItemSelector(state, simPlanId),
    // 	simPlan
    // };
};

const mapDispatchToProps = (dispatch, props) => {
    const {simPlanId} = props;
    return {
        setDate: date => dispatch(setSimPlanOrderItemDate(simPlanId, date)),
        add: duration => dispatch(addSimPlanOrderItem(simPlanId, duration)),
        remove: () => dispatch(removeSimPlanOrderItem(simPlanId))
    };
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(OrderSummaryItem);
