import { createReducer, createAction } from 'redux-starter-kit';
import { format, addDays } from 'date-fns';
import { formValueSelector } from 'redux-form';
import apiConfig from '../config/apiConfig';
const initialState = {
	items: {},
	orderData: {},
	phasesCompletes: [],
	simCards: [],
	fetching: false,
	errors: ''
};

export const clearOrder = createAction('@@order/clear');

const actions = {
	[clearOrder]: () => {
		return initialState;
	},
	'@@order/ORDER_SUCCESS': (state, action) => {
		state.fetching = false;
		state.orderData = action.payload.data;
	},
	'@@order/ORDER_REQUEST': (state, action) => {
		state.fetching = true;
	},
	'@@order/ORDER_FAILED': (state, action) => {
		state.fetching = false;
		state.errors = 'FAILED';
	},
	'@@order/SIM_PLAN_ORDER_ITEM_DATE_SET': (state, action) => {
		const { simPlanId, startDate, endDate } = action.payload;
		if (!state.items[simPlanId]) state.items[simPlanId] = { quantity: 0 };
		state.items[simPlanId].startDate = startDate;
		state.items[simPlanId].endDate = endDate;
	},
	'@@order/SIM_PLAN_ORDER_ITEM_REMOVED': (state, action) => {
		const { simPlanId } = action.payload;
		if (!state.items[simPlanId]) {
			state.items[simPlanId] = { quantity: 0 };
		} else if (state.items[simPlanId].quantity > 1) {
			--state.items[simPlanId].quantity;
		} else {
			delete state.items[simPlanId];
		}
	},
	'@@order/SIM_PLAN_ORDER_ITEM_ADDED': (state, action) => {
		const { simPlanId, duration } = action.payload;
		if (!state.items[simPlanId])
			state.items[simPlanId] = {
				quantity: 0,
				startDate: format(new Date(), 'yyyy-MM-dd'),
				endDate: format(addDays(new Date(), duration), 'yyyy-MM-dd')
			};
		++state.items[simPlanId].quantity;
	},
	'@@order/SIM_PLAN_ORDER_SHIPPING_METHOD_SELECTED': (state, action) => {
		state.shippingMethod = action.payload;
	},
	'@@order/SIM_PLAN_ORDER_SHIPPING_INFORMATION_FIELD_SET': (state, action) => {
		const { field, value } = action.payload;
		state.shippingInformation[field] = value;
	},
	'@@order/PHASE_COMPLETED': (state, action) => {
		state.phasesCompletes = [
			...new Set([...state.phasesCompletes, action.payload])
		];
	}
};

const orderReducer = createReducer(initialState, actions);

const normalizePhoneNumber = (phoneNumber: string) => {
	return phoneNumber;
};

export function createOrder(orderDetails) {
	return function(dispatch, getState) {
		dispatch({ type: '@@order/ORDER_REQUEST' });
		const fullNameFiltered = orderDetails.shipping.fullName.split(' ').filter(element => element != '');
		const firstName = fullNameFiltered[0];
		const lastName = fullNameFiltered.slice(1).reduce((x, y) => x.concat(' ' + y))
		const plans = getState().order.items;

		const captchaRef = orderDetails.captchaRef
		
		const order = {
			simsRentalsData: Object.keys(plans).map(planId => ({
				planId,
				...plans[planId]
			})),
			shipping_method: orderDetails.shippingMethod,
			first_name: firstName,
			last_name: lastName,	
			phone_number: normalizePhoneNumber(orderDetails.shipping.phoneNumber),
			email: orderDetails.shipping.email,
			street: orderDetails.shipping.street || '',
			house_number: orderDetails.shipping.houseNumber || '',
			apartment: orderDetails.shipping.apartment || '',
			city: orderDetails.shipping.city || '',
			country: orderDetails.shipping.country || '',
			state: orderDetails.shipping.state || '',
			postal_code: orderDetails.shipping.postalCode || '',
			cc_owner_name: orderDetails.billing.cardHoldersName,
			cc_number: orderDetails.billing.creditCardNumber,
			cc_valid_thru: orderDetails.billing.expiration.replace('/', ''),
			cc_cvv: orderDetails.billing.cvv,
			comments: orderDetails.comments,
            captcha_ref: captchaRef
		};
		console.log(order);
		return fetch(apiConfig.apiUrl + '/orders/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify(order)
		})
			.then(async res => {
				const payload = await res.json();
				if (!payload.success) {
					console.log(payload);
					return dispatch({ type: '@@order/ORDER_FAILED', errors: payload.errors});
				}
				dispatch({ type: '@@order/ORDER_SUCCESS', payload });
			})
			.catch(e => {
				console.log(e);
				dispatch({ type: '@@order/ORDER_FAILED', errors: "server error" });
			});
	};
}

export function completePlansSelection() {
	return {
		type: '@@order/PHASE_COMPLETED',
		payload: 'PLANS'
	};
}

export function completeShippingInfo() {
	return {
		type: '@@order/PHASE_COMPLETED',
		payload: 'SHIPPING'
	};
}

export function completeBillingInfo() {
	return {
		type: '@@order/PHASE_COMPLETED',
		payload: 'Billing'
	};
}

export function setShippingInformationFieldValue(field, value) {
	return {
		type: '@@order/SIM_PLAN_ORDER_SHIPPING_INFORMATION_FIELD_SET',
		payload: { field, value }
	};
}
export function selectShippingMethod(shippingMethod) {
	return {
		type: '@@order/SIM_PLAN_ORDER_SHIPPING_METHOD_SELECTED',
		payload: shippingMethod
	};
}
export function removeSimPlanOrderItem(simPlanId, duration) {
	return {
		type: '@@order/SIM_PLAN_ORDER_ITEM_REMOVED',
		payload: {
			simPlanId
		}
	};
}

export function addSimPlanOrderItem(simPlanId, duration) {
	return {
		type: '@@order/SIM_PLAN_ORDER_ITEM_ADDED',
		payload: {
			simPlanId,
			duration
		}
	};
}

export function setSimPlanOrderItemDate(simPlanId, { startDate, endDate }) {
	return {
		type: '@@order/SIM_PLAN_ORDER_ITEM_DATE_SET',
		payload: {
			simPlanId,
			startDate,
			endDate
		}
	};
}

export const simPlanOrderItemSelector = (state, simPlanId) => {
	const simPlan = state.order.items[simPlanId];
	const now = new Date();
	const today = format(now, 'yyyy-MM-dd');
	if (!simPlan) {
		return {
			startDate: format(now, 'yyyy-MM-dd'),
			endDate: format(
				addDays(now, state.simPlans.byId[simPlanId].duration - 1),
				'yyyy-MM-dd'
			),
			quantity: 0
		};
	}
	return {
		...simPlan,
		startDate: simPlan.startDate || today,
		endDate: simPlan.endDate || today,
		quantity: simPlan.quantity || 0
	};
};

export const orderItemsCountSelector = state => {
	return Object.keys(state.order.items).reduce(function(acc, cur) {
		return acc + state.order.items[cur].quantity;
	}, 0);
};

export const orderItemsTotalPriceSelector = state => {
	return Object.keys(state.order.items).reduce(function(acc, cur) {
		return (
			acc + state.simPlans.byId[cur].price * state.order.items[cur].quantity
		);
	}, 0);
};

export const orderTotalPriceSelector = state => {
	const formSelector = formValueSelector('order');
	const selectedShippingMethod =
		state.shippingMethods.byId[formSelector(state, 'shippingMethod')];
	const shippingPrice = selectedShippingMethod
		? selectedShippingMethod.price
		: 0;
	return orderItemsTotalPriceSelector(state) + shippingPrice;
};
export default orderReducer;
