import React from 'react';
import './progress-bar.css';

function ProgressBar(props) {
	return (
		<div className={'omni-progress-container ' + props.className}>
			<div className="omni-progress-fill">
				<div className="omni-progress-knob" />
			</div>
		</div>
	);
}

export default ProgressBar;
