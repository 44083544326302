function categoryMapper(category) {
  const categoryMap = {
    isr: "israeli-sim",
    usa: "american-sim",
    eur: "european-sim",
    global: "global-sim",
    esim: "esim-sim",
  };

  return categoryMap[category];
}
export function planTypeCodeMapper(category) {
  const categoryMap = {
    "israeli-sim": "isr",
    "american-sim": "usa",
    "european-sim": "eur",
    "global-sim": "global",
    "esim-sim": "esim",
  };

  return categoryMap[category];
}

export default categoryMapper;
