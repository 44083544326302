import React from 'react';
import { Redirect, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';
import { signOut } from '../../ducks/auth';

function SignOut(props) {
	React.useEffect(() => {
		props.signOut();
	});

	return <Redirect to={'/' + props.match.params.locale} />;
}

export default connect(
	null,
	{ signOut: signOut }
)(withRouter(SignOut));
