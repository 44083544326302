import React from 'react';
import './calls-history.css';
import '../../components/Table/table.css';
import { useTranslation, Trans } from 'react-i18next';
import classNames from 'classnames';
import { ProgressBar } from '../../components';
import { format, addDays } from 'date-fns';
import { connect } from 'react-redux';
import apiConfig from '../../config/apiConfig';
import SelectSimCard from '../../components/SelectSimCard/SelectSimCard';

const reducer = (state, action) => {
	switch (action.type) {
		case 'CHANGE':
			return {
				...state,
				values: {
					...state.values,
					[action.payload.field]: action.payload.value
				}
			};
		case 'SUBMIT':
			return {
				...state,
				history: {
					...state.history,
					fetching: true,
					submitSecceeded: true
				}
			};
		case 'SUCCESS':
			return {
				...state,
				history: {
					...state.history,
					fetching: false,
					log: action.payload,
					submitSecceeded: true
				}
			};
		case 'ERROR':
			return {
				...state,
				history: {
					...state.history,
					fetching: false,
					error: action.payload,
					submitSecceeded: true
				}
			};

		case 'VALIDATION_ERROR':
			return {
				...state,
				errors: {
					...state.errors,
					[action.payload.field]: action.payload.error
				}
			};
		default:
			return state;
	}
};

const initialState = {
	errors: {
		simCard: ''
	},
	values: {
		startDate: format(new Date(), 'yyyy-MM-dd'),
		endDate: format(addDays(new Date(), 30), 'yyyy-MM-dd'),
		details: false,
		simCard: ''
	},
	submitting: false,
	history: {
		fetching: false,
		error: '',
		log: [],
		submitSecceeded: false
	}
};

function CallsHistory(props) {
	const { t } = useTranslation();
	const {
		match: {
			params: { locale }
		}
	} = props;
	const isRTL = locale === 'he';
	const [state, dispatch] = React.useReducer(reducer, initialState);
	const handleChange = e => {
		dispatch({
			type: 'CHANGE',
			payload: { field: e.target.name, value: e.target.value }
		});
	};

	const handleSubmit = () => {
		if (!state.values.simCard) {
			return dispatch({
				type: 'VALIDATION_ERROR',
				payload: { field: 'simCard', error: 'invalid' }
			});
		}
		dispatch({ type: 'SUBMIT' });
		fetch(apiConfig.apiUrl + '/sims/history/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
				Authorization: 'Bearer ' + props.token
			},
			body: JSON.stringify({
				sim_number: state.values.simCard.value,
				start_date: state.values.startDate,
				end_date: state.values.endDate
			})
		})
			.then(async res => {
				const payload = await res.json();
				if (!payload.success) {
					dispatch({ type: 'ERROR', payload: payload.errors });
					return;
				}
				dispatch({ type: 'SUCCESS', payload: payload.data });
			})
			.catch(e => {
				dispatch({ type: 'ERROR', payload: String(e) });
				return;
			});
	};

	return (
		<div
			className={classNames({
				'calls-history-container': true,
				rtl: isRTL
			})}>
			<div className="calls-history-form-container">
				<div>
					<label>{t('Phone number')}</label>
					<SelectSimCard
						className="call-history-sim-card-select"
						value={state.values.simCard}
						onChange={c =>
							dispatch({
								type: 'CHANGE',
								payload: { field: 'simCard', value: c }
							})
						}
					/>
				</div>
				<div className="row space">
					<div>
						<label>{t('Start date')}</label>
						<input
							type="date"
							name="startDate"
							value={state.values.startDate}
							onChange={handleChange}
						/>
					</div>
					<div>
						<label>{t('End date')}</label>
						<input
							type="date"
							max={addDays(30, new Date())}
							name="endDate"
							value={state.values.endDate}
							onChange={handleChange}
						/>
					</div>
					{/* 		<div className="row">
						<input
							type="checkbox"
							className="omni-checkbox"
							name="details"
							value={state.values.details}
							onChange={handleChange}
						/>
						<label>{t('Details')}</label>
					</div> */}

					<button onClick={handleSubmit}>{t('Search')}</button>
					{/* <button className="inverse">{t('Download')}</button> */}
				</div>
			</div>
			{state.history.fetching && <ProgressBar />}
			<div>
				<table className="omni-table">
					<thead>
						<tr>
							<td>{t('Duration')}</td>
							{/* <td>{t('LG TG')}</td> */}
							<td>{t('Call ID')}</td>
							<td>{t('Caller')}</td>
							<td>{t('Destination')}</td>
							<td>{t('Minutes/MB')}</td>
							<td>{t('Rate')}</td>
							<td>{t('Cost')}</td>
							<td>{t('Area')}</td>
							<td>{t('State')}</td>
						</tr>
					</thead>
					<tbody>
						{state.history.log.map(logItem => {
							return (
								<tr key={logItem.CallId}>
									<td>{logItem.ConnectionTime}</td>
									{/* <td>{logItem.CallType}</td> */}
									<td>{logItem.CallId}</td>
									<td>{logItem.SourceNumber}</td>
									<td>{logItem.DestinationNumber}</td>
									<td>{logItem.MbOrMinutes}</td>
									<td>{logItem.Rate}</td>
									<td>{logItem.Cost}</td>
									<td>{logItem.Region}</td>
									<td>{logItem.State}</td>
								</tr>
							);
						})}
					</tbody>
					{state.history.submitSecceeded && !state.history.log.length && (
						<tfoot>
							<tr>
								<td colSpan={10}>
									<Trans>No available records</Trans>
								</td>
							</tr>
						</tfoot>
					)}
				</table>
			</div>
		</div>
	);
}

const mapStateToProps = state => {
	return {
		token: state.auth.accessToken
	};
};

const dispatchProps = {};

export default connect(
	mapStateToProps,
	dispatchProps
)(CallsHistory);
