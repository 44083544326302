import React from 'react';
import './screen-card-section.css';

function ScreenCardSection({ title, children }) {
	return (
		<div className="screen-card-section">
			<div className="screen-card-section-strip">
				<span>{title}</span>
			</div>
			<div>{children}</div>
		</div>
	);
}

export default ScreenCardSection;
