//@flow
import React from 'react';
import { withRouter } from 'react-router-dom';
import classNames from 'classnames';
import './tooltip.css';
function Tooltip(props) {
	const {
		title,
		text,
		match: {
			params: { locale }
		}
	} = props;
	const isRTL = locale === 'he';
	return (
		<div style={props.style}>
			<div
				style={{
					backgroundRepeat: 'no-repeat',
					backgroundSize: 'contain',
					backgroundImage: `url(${process.env.PUBLIC_URL +
						'/assets/tooltip.png'})`
				}}
				className={classNames({ 'tooltip-container': true, rtl: isRTL })}>
				<img
					src={process.env.PUBLIC_URL + '/assets/red-star.png'}
					alt="star"
					className="tooltip-icon"
				/>
				<div className="tooltip-content">
					<div>
						<span className="tooltip-title">{title}</span>
						<span className="tooltip-text">{text}</span>
					</div>
				</div>
			</div>
		</div>
	);
}
export default withRouter(Tooltip);
