import React from 'react';

const ResetPasswordConfirm = props => {
	return (
		<div
			style={{
				padding: 40,
				textAlign: 'center',
				direction: props.match.params.locale === 'he' ? 'rtl' : 'ltr'
			}}>
			אימייל לאיפוס סיסמה נשלח לכתובת האימייל של החשבון, אבל ייתכן שיקח מספר
			דקות עד שיופיע בתיבת הדואר הנכנס. נא להמתין 10 דקות לפני נסיון נוסף לאיפוס
			הסיסמה.
		</div>
	);
};

export default ResetPasswordConfirm;
