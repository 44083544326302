import React from 'react';
import { useTranslation, Trans } from 'react-i18next';
import SelectCarrier from '../../components/SelectCarrier/SelectCarrier';
import SelectCountry from '../../components/SelectCountry/SelectCountry';
import { getRates, hashRate } from '../../ducks/rates';
import { ProgressBar } from '../../components';
import { connect } from 'react-redux';
import { formatRatesPrice } from '../../utils/priceFormatter';

const reducer = (state, action) => {
	switch (action.type) {
		case 'source_country_selected': {
			return { ...state, source: { ...state.source, country: action.payload } };
		}
		case 'source_carrier_selected': {
			return { ...state, source: { ...state.source, carrier: action.payload } };
		}
		case 'destination_country_selected': {
			return {
				...state,
				destination: { ...state.destination, country: action.payload }
			};
		}
		case 'destination_carrier_selected': {
			return {
				...state,
				destination: { ...state.destination, carrier: action.payload }
			};
		}
		default:
			return state;
	}
};

const initialState = {
	source: {
		country: null,
		carrier: null
	},
	destination: {
		country: null,
		carrier: null
	}
};

const Calls = props => {
	const { t } = useTranslation();

	const [state, dispatch] = React.useReducer(reducer, initialState);

	const handleSubmit = () => {
		props.getRates({
			location: state.source.carrier.value,
			destination: state.destination.carrier.label
		});
	};

	const rate =
		state.source.carrier && state.destination.carrier
			? props.rates[
					hashRate(state.source.carrier.value, state.destination.carrier.label)
			  ]
			: null;
	const incomingCost = rate ? formatRatesPrice(rate.CostIncomingCall) : '';
	const costIsrNumIncomning = rate ? formatRatesPrice(rate.CostIsrNumIncomning) : '';
	const directOutgoingCost = rate
		? formatRatesPrice(rate.CostOutgoingCall)
		: '';
	const callbackCallCost = rate ? formatRatesPrice(rate.CallBack) : '';
	return (
		<div>
			<div className="admin-rates-card">
				<div className="admin-rates-card-header">
					<h4>{t('Call source')}</h4>
					<img src={process.env.PUBLIC_URL + '/assets/note.png'} alt="note" />
					<span>?</span>
				</div>
				<div className="admin-rates-card-body">
					<div className="admin-rates-action-row">
						<div className="row">
							<label>{t('Country')}</label>
							<SelectCountry
								source
								value={state.source.country}
								onChange={c =>
									dispatch({ type: 'source_country_selected', payload: c })
								}
							/>
						</div>
						<div className="row">
							<label>{t('Carrier')}</label>
							<SelectCarrier
								isSource
								countryIsoCode={
									state.source.country && state.source.country.value
								}
								value={state.source.carrier}
								onChange={c =>
									dispatch({ type: 'source_carrier_selected', payload: c })
								}
							/>
						</div>
					</div>
				</div>
			</div>
			<div className="admin-rates-card">
				<div className="admin-rates-card-header">
					<h4>{t('Call destination')}</h4>
					<img src={process.env.PUBLIC_URL + '/assets/note.png'} alt="note" />
					<span>?</span>
				</div>
				<div className="admin-rates-card-body">
					<div className="admin-rates-action-row">
						<div className="row">
							<label>{t('Country')}</label>
							<SelectCountry
								value={state.destination.country}
								onChange={c =>
									dispatch({ type: 'destination_country_selected', payload: c })
								}
							/>
						</div>
						<div className="row">
							<label>{t('Carrier')}</label>
							<SelectCarrier
								countryIsoCode={
									state.destination.country && state.destination.country.value
								}
								value={state.destination.carrier}
								onChange={c =>
									dispatch({ type: 'destination_carrier_selected', payload: c })
								}
							/>
						</div>
						<div>
							<button
								className="admin-rates-button"
								type="button"
								onClick={handleSubmit}>
								{t('Calculate')}
							</button>
						</div>
					</div>
				</div>
			</div>
			{props.loading && <ProgressBar />}
			{incomingCost && (
				<div className="row">
					<div className="row admin-rates-call-info">
						<img
							src={process.env.PUBLIC_URL + '/assets/incoming-call.png'}
							alt="incoming"
						/>
						<div>
							<span>
								<Trans price={incomingCost}>
								Incoming call through British number{' '}
									<b>{{ incomingCost }}</b>
								</Trans>
							</span>
							<br />
							<span>
								<Trans price={costIsrNumIncomning}>
								Incoming call through Israeli number{' '}
									<b>{{ costIsrNumIncomning }}</b>
								</Trans>
							</span>
						</div>
					</div>

					<div className="row admin-rates-call-info">
						<img
							src={process.env.PUBLIC_URL + '/assets/outgoing-call.png'}
							alt="incoming"
						/>
						<div>
							<span>
								<Trans price={directOutgoingCost}>
								Direct Outgoing call{' '}
									<b>{{ directOutgoingCost }}</b>
								</Trans>
							</span>
							<br />
							<span>
								<Trans price={callbackCallCost}>
									Callback call{' '}
									<b>{{ callbackCallCost }}</b>
								</Trans>
							</span>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

const mapStateToProps = state => {
	return {
		loading: state.rates.fetching,
		rates: state.rates.byLocationAndDestination
	};
};

const dispatchProps = {
	getRates
};
export default connect(
	mapStateToProps,
	dispatchProps
)(Calls);
