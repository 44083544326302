import React from 'react';
import './contactUs.css';
import { Screen } from '../../components';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { reduxForm, Field } from 'redux-form';
import validate from '../../utils/validator';
import ContactUsForm from '../../components/ContactUsForm/ContactUsForm';
import ContactInfo from '../../components/ContactInfo/ContactInfo';


function ContactUs(props) {

	const {match:{params:{locale}}} = props;
	const isRTL = locale === 'he';
	const { t } = useTranslation();
	return (
		<Screen customFooterContent={t('footers:' + 'contact-us') } footerStyle="contactUs-footer">
			<div className={classNames({'page-container-contactUs': true, 'page-container': true, rtl: isRTL })}>
				<div className='contactUs-title'>
					<div className="contactUs-heading">
                        <span className="decorated-heading">
                        <img src={process.env.PUBLIC_URL + '/assets/red-star.png'} alt="star" 
                            className={classNames({'star-icon': true, rtl: isRTL})}
                        />
                            {t('Contact Us')}
                        </span>
                    </div>
                    <p>
                        {t('Would you like to know more about us and our SIM cards?')}
                        {isRTL && <span className="markedText">נשמח לעמוד לרשותכם.</span>}
                    </p>
                    <p>{t("Add your details below and we'll contact you shortly.")}</p>
				</div>
				<div className='contactUs-content'>
                    <ContactUsForm match={props.match}/>
                    <div className={classNames({'separator': true, rtl: isRTL})}></div>
                    <ContactInfo locale={locale}/>
				</div>
			</div>
		</Screen>
	)
}
export default ContactUs;

