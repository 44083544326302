import React from 'react';
import { Screen, AdminTabBar } from '../../components';
import { Switch, Route, Link, Redirect } from 'react-router-dom';
import './admin.css';
import { SignIn, PurchaseHistory } from '..';
import { useTranslation } from 'react-i18next';
import CallsHistory from '../CallsHistory/CallsHistory';
import Rates from '../Rates/Rates';
import Status from '../Status';
import { connect } from 'react-redux';
import SignOut from '../SignOut/SignOut';
import ResetPassword from '../ResetPassword/ResetPassword';
import ResetPasswordConfirm from '../ResetPassword/ResetPasswordConfirm';
import ChangePassword from '../ResetPassword/ChangePassword';
function Admin(props) {
	const { t } = useTranslation();
	const {
		match: {
			params: { locale }
		}
	} = props;
	return (
		<div className="admin-container">
			<Screen title={t('Admin - Global SIM management')} theme="red">
				<div className="admin-container-box">
					{!props.initializingAuth && (
						<div className="admin-router-container">
							<Switch>
								<Route
									exact
									path={props.match.path + '/password/reset'}
									component={ResetPassword}
								/>
								<Route
									exact
									path={props.match.path + '/password/reset/confirm'}
									component={ResetPasswordConfirm}
								/>
								<Route
									path={
										props.match.path + '/password/reset/confirm/:uid/:token'
									}
									component={ChangePassword}
								/>

								{!props.currentUser ? (
									<Route component={SignIn} />
								) : (
									<Route>
										<div className="admin-panel-router-container">
											<AdminTabBar />
											<div>
												<Switch>
													<Route
														path={props.match.path + '/purchase-history'}
														component={PurchaseHistory}
													/>
													<Route
														path={props.match.path + '/calls-history'}
														component={CallsHistory}
													/>

													<Route
														path={props.match.path + '/rates'}
														component={Rates}
													/>
													<Route
														path={props.match.path + '/status'}
														component={Status}
													/>
													<Route
														path={props.match.path + '/sign-out'}
														component={SignOut}
													/>
													<Route>
														<Redirect to={props.match.url + '/status'} />
													</Route>
												</Switch>
											</div>
										</div>
									</Route>
								)}
							</Switch>
						</div>
					)}

					<div className="admin-footer">
						<Link to={'/' + locale + '/terms-of-service'}>
							{t('Terms of service')}
						</Link>
						<Link to={'/' + locale + '/privacy-policy'}>
							{t('Privacy policy')}
						</Link>
					</div>
				</div>
			</Screen>
		</div>
	);
}

const mapStateToProps = state => {
	return {
		initializingAuth: state.auth.initializing,
		currentUser: state.auth.currentUser
	};
};

export default connect(mapStateToProps)(Admin);
