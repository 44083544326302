import React from 'react';
import './text-field.css';
import Input from '../Input/Input';
import { useTranslation } from 'react-i18next';

function TextField(props) {
	const { label, error, touched } = props;
	const { t } = useTranslation();
	return (
		<div>
			<div className="textfield-container">
				{label && <label className="textfield-label">{label}</label>}
				<Input
					{...props}
					className="textfield-input"
					style={{
						border: props.touched && props.error ? '1px solid red' : undefined
					}}
				/>
			</div>
			{error && touched && (
				<span className="omni-input-error">{t('errors:' + error)}</span>
			)}
		</div>
	);
}

export default TextField;
