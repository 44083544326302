import React from 'react';
import './styles.css';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import categoryMapper from '../../utils/categoryMapper';

function PromoCard(props) {
	const {
		title,
		subtitle,
		extra,
		category,
		imageSrc,
		match: {
			url,
			params: { locale }
		}
	} = props;
	const isRTL = locale === 'he';
	return (
		<div className="promo-card-container">
			<Link
				to={url + '/' + categoryMapper(category)}
				className={classNames({
					'card-image': true,
					rtl: isRTL
				})}
				style={{ backgroundImage: `url(${imageSrc})` }}>
				<span
					className={classNames({
						title: true,
						rtl: isRTL
					})}>
					{title}
				</span>
				<span
					className={classNames({
						subtitle: true,
						rtl: isRTL
					})}>
					{subtitle}
				</span>
			</Link>
			{extra && (
				<div
					className={classNames({
						'promo-card-extra': true,
						rtl: isRTL
					})}>
					{extra()}
				</div>
			)}
		</div>
	);
}

export default withRouter(PromoCard);
