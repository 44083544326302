import React from 'react';
import Select from 'react-select';
import { connect } from 'react-redux';
import { getCountries } from '../../ducks/countries';
import { useTranslation } from 'react-i18next';
import apiConfig from '../../config/apiConfig';
import { withRouter } from 'react-router-dom';

//const isSource = c => c.is_source;

const SelectCountry = React.memo(
	withRouter(props => {
		const { t } = useTranslation();
		const { locale } = props.match.params;
		const [deliveryCountries, setDeliveryCountries] = React.useState([]);
		React.useEffect(() => {
			if (props.static) {
				const abortController = new AbortController();
				const signal = abortController.signal;
				fetch(apiConfig.apiUrl + '/shipping/countries/', { signal })
					.then(async res => {
						if (res.ok) {
							const payload = await res.json();
							const data = payload.results;
							setDeliveryCountries(
								data.map(country => ({
									label: country['title_' + locale],
									value: country.title_en
								}))
							);
						}
					})
					.catch(console.log);
				return () => abortController.abort();
			} else {
				props.getCountries();
			}
		}, [locale]);

		const options = props.static
			? deliveryCountries
			: props.countries
					//.filter(country => (props.source ? isSource(country) : true))
					.map(country => ({ value: country.iso_code, label: country.name }));

		return (
			<Select
				placeholder={t('Select')}
				theme={theme => {
					return {
						...theme,
						borderRadius: 0
					};
				}}
				className={props.className + ' admin-rates-select'}
				options={options}
				{...props}
			/>
		);
	})
);

const mapStateToProps = state => {
	return {
		loading: state.countries.fetching,
		countries: state.countries.data
	};
};

const dispatchProps = {
	getCountries
};

export default connect(
	mapStateToProps,
	dispatchProps
)(SelectCountry);
