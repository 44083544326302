import React from 'react';
import './styles.css';
import { withRouter, Link } from 'react-router-dom';
import classNames from 'classnames';
import { withTranslation, Trans } from 'react-i18next';
function Footer(props) {
	const { locale } = props.match.params;
	const isRTL = locale === 'he';

	return (
		<footer
			className={classNames({
				footer: true,
				rtl: isRTL
			})}>
			<div className="footer-container">
				<span>
					<h1 className="heading">
						<Trans>
							Already a member?
							<Link
								className="underline"
								to={'/' + props.match.params.locale + '/admin'}>
								Click here
							</Link>
						</Trans>
					</h1>
				</span>
				<div className="footer-contact-container">
					<span>
						<Trans>
							For further information and for large groups orders contact us
						</Trans>
						:
					</span>
					<span><a href="tel: 1-212-9996029">1-212-9996029 </a>/ <a href="tel: 03-7222222">03-7222222 </a></span>
				</div>
			</div>
			<div className="footer-credits">
				<div>
					<a href="https://kokoapps.co.il/">
						<img
							src={process.env.PUBLIC_URL + '/assets/kokoapps.png'}
							alt="kokoapps"
						/>
						<span>Powered by KokoApps</span>
					</a>
				</div>
			</div>
		</footer>
	);
}

export default withRouter(withTranslation()(Footer));
