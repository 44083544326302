exports.normalizeCCExpiration = value => {
	if (!value) return '';
	const numericValue = value.replace('/', '');
	if (numericValue.length <= 2) {
		return numericValue;
	}
	const month = numericValue.slice(0, 2);
	const year = numericValue.slice(2);
	return `${month}/${year}`;
};
