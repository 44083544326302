function createActions(resource: string) {
	const REQUEST = resource + '_REQUESTED';
	const SUCCESS = resource + '_SUCCEEDED';
	const FAIL = resource + '_FAILED';
	return [REQUEST, SUCCESS, FAIL];
}

const apiMiddleware = store => next => action => {
	if (action.type !== 'API') return next(action);
	const { url, method = 'POST', resource, headers, body } = action.payload;
	const [request, success, failed] = createActions(resource);
	const appState = store.getState().appState;
	const locale = appState.locale;
	const token = store.getState().auth.accessToken;
	const requestHeaders = {
		'Content-Type': 'application/json',
		//	'X-Session-Id': sessionId,
		'X-Locale': locale,
		Authorization: token ? 'Bearer ' + token : undefined,
		...headers
	};
	const requestBody = {
		//SessionID: store.getState().appState.session.id,
		...body
	};
	const requestParams = {
		method,
		headers: requestHeaders
	};

	if (requestBody && method === 'POST')
		requestParams.body = JSON.stringify(requestBody);

	next({ type: request, payload: { url, ...requestParams } });
	fetch(url, requestParams)
		.then(response => (response && response.ok ? response.json() : 'success'))
		.then(payload => {
			next({ type: success, payload });
		})
		.catch(error => {
			console.log(error);
			next({ type: failed, payload: String(error) });
		});
};

export default apiMiddleware;
