//@flow
import React from 'react';


function TextArea(props): React.ElementType {
	return (
		<textarea {...props} className="inputStyle textAreaStyle"/>
	);
}

export default TextArea;
