import React from 'react';
import './contactUs.css';
import { Screen } from '../../components';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import { reduxForm, Field } from 'redux-form';
import validate from '../../utils/validator';
import { clearState } from '../../ducks/contactUs';
import { connect } from 'react-redux';
import ContactUsForm from '../../components/ContactUsForm/ContactUsForm';

function ContactUsTy(props) {

	const {match:{params:{locale}}} = props;
	const isRTL = locale === 'he';
    const { t } = useTranslation();
    React.useEffect(() => {
		return props.clearState()
    }, []);
    
	return (
		<Screen>
			<div className={classNames({'page-container-contactUs-ty': true, rtl: isRTL })}>
                {isRTL ? 
                    (
                        <div>
                            <div className="contactUs-ty-content">
                                <img src={process.env.PUBLIC_URL + '/assets/red-star.png'} alt="star" 
                                    className={classNames({'star-icon-ty': true, rtl: isRTL})}
                                />        
                                <span>תודה שפנית אלינו,&nbsp;</span>
                                <span className="markedText">פנייתך תענה בהקדם</span>
                            </div>
                        </div>
                    ) 
                    : 
                    (
                        <div>    
                            <div className="contactUs-ty-content">
                                <img src={process.env.PUBLIC_URL + '/assets/red-star.png'} alt="star" 
                                    className={classNames({'star-icon-ty': true, rtl: isRTL})}
                                />       
                                <span>Thank you. &nbsp;</span>
                                <span className="markedText"> The form was sent successfully.</span>
                            </div>
                        </div>
                    )
                }
			</div>
		</Screen>
	)
}

const dispatchProps = {
	clearState
};

export default connect(
	null,
	dispatchProps
)(ContactUsTy);

