import { createReducer } from 'redux-starter-kit';

const initialState = {
	byId: {}
};

const actions = {
	PAGE_SUCCEEDED: (state, action) => {
		state.byId[action.payload.id] = action.payload;
	}
};

const pagesReducer = createReducer(initialState, actions);
export default pagesReducer;

export function getPage(id) {
	const { data } =
		id === 1
			? require('./mocks/countriesPage.json')
			: require('./mocks/countryRatesPage.json');
	return {
		type: 'PAGE_SUCCEEDED',
		payload: data
	};
}
