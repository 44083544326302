import React from 'react';
import '../ShippingInfoForm/shipping-info-form.css';
import { Input } from '..';
import { useTranslation } from 'react-i18next';
import { reduxForm, Field } from 'redux-form';
import validate from '../../utils/validator';
function renderInput(field) {
	const { t } = useTranslation();
	return (
		<div style={{ ...field.style, flexDirection: 'column', display: 'flex' }}>
			<Input
				{...field.input}
				style={{
					flex: 1,
					border:
						field.meta.error && field.meta.touched ? '1px solid red' : undefined
				}}
			/>
			{field.meta.error && field.meta.touched && (
				<span className="omni-input-error">
					{t('errors:' + field.meta.error)}
				</span>
			)}
		</div>
	);
}
function ContactInformationForm(props) {
	const { t } = useTranslation();
	return (
		<div className="shipping-info-form">
			<div className="field">
				<label style={{ flex: 4 }}>{t('Full name')}</label>
				<Field
					name="shipping.fullName"
					component={renderInput}
					style={{ flex: 8 }}
				/>
			</div>
			<div className="field">
				<label style={{ flex: 4 }}>{t('Phone number')}</label>
				<Field
					autocomplete="tel"
					name="shipping.phoneNumber"
					component={renderInput}
					style={{ flex: 8 }}
				/>
			</div>
			<div className="field">
				<label style={{ flex: 3 }}>{t('Email')} </label>
				<Field
					autocomplete="email"
					name="shipping.email"
					component={renderInput}
					style={{ flex: 9 }}
				/>
			</div>
		</div>
	);
}
export default ContactInformationForm
// export default reduxForm({
// 	form: 'order',
// 	destroyOnUnmount: false,
// 	validate: validate
// })(ContactInformationForm);
