import ReactGA from 'react-ga';
const trackingMiddleware = store => next => action => {

  //sim purchase related
  let orderSimCategory = 'Order Sim';
  if(action.type === "@@order/SIM_PLAN_ORDER_ITEM_ADDED"){
    let category = orderSimCategory;
    let event_action = "Added Item";
    let label = "Sim Plan Id: " + action.payload.simPlanId;
    addEvent(category, event_action, label);
  }
  if(action.type === "@@order/SIM_PLAN_ORDER_ITEM_REMOVED"){
    let category = orderSimCategory;
    let event_action = "Removed Item";
    let label = "Sim Plan Id: " + action.payload.simPlanId;
    addEvent(category, event_action, label);
  }
  if(action.type === "@@order/SIM_PLAN_ORDER_ITEM_DATE_SET"){
    let category = orderSimCategory;
    let event_action = "Item Set Date";
    let label = `New date: ${action.payload.startDate} - ${action.payload.endDate}`;
    addEvent(category, event_action, label);
  }
  if(action.type === "@@order/ORDER_REQUEST"){
    let category = orderSimCategory;
    let event_action = "Order Request";
    addEvent(category, event_action);
  }
  if(action.type === "@@order/ORDER_SUCCESS"){
    let category = orderSimCategory;
    let event_action = "Order Success";
    let label = "Transaction ID: " + action.payload.data.transaction_id;
    addEvent(category, event_action, label);
  }
  if(action.type === "@@order/ORDER_FAILED"){
    let category = orderSimCategory;
    let event_action = "Order Failed";
    //let label = action.payload.errors.message
    //addEvent(category, event_action, label);
    addEvent(category, event_action);
  }

  //contactUs related
  let contactUsCategory = "Contact Us";
  if(action.type === "@@contactUs/FETCH_REQUEST"){
    let category = contactUsCategory;
    let event_action = "ContactUs Request";
    addEvent(category, event_action);
  }
  if(action.type === "@@contactUs/FETCH_SUCCEEDED"){
    let category = contactUsCategory;
    let event_action = "ContactUs Success";
    addEvent(category, event_action);
  }
  if(action.type === "@@contactUs/FETCH_FAILED"){
    let category = contactUsCategory;
    let event_action = "ContactUs Failed";
    addEvent(category, event_action);
  }

  //admin related
  let adminCategory = "Admin";
  if(action.type === "@@auth/SIGN_IN_SUCCESS"){
    let category = adminCategory;
    let event_action = "Sign In Success";
    addEvent(category, event_action);
  }
  if(action.type === "@@auth/SIGN_IN_FAILED"){
    let category = adminCategory;
    let event_action = "Sign In Failed";
    addEvent(category, event_action);
  }
  if(action.type === "@@auth/SIGNED_OUT"){
    let category = adminCategory;
    let event_action = "Signed Out";
    addEvent(category, event_action);
  }

  //topUp related
  let topUpCategory = "Top Up";
  if(action.type === "@@account/topup_request"){
    let category = topUpCategory;
    let event_action = "Top Up Request";
    let label = `collectItId: ${store.getState().auth.currentUser.collectitid}`;
    addEvent(category, event_action, label);
  }
  if(action.type === "@@account/topup_request_success"){
    let category = topUpCategory;
    let event_action = "Top Up Success";
    let label = `collectItId: ${store.getState().auth.currentUser.collectitid}`;
    addEvent(category, event_action, label);
  }
  if(action.type === "@@account/topup_request_failed"){
    let category = topUpCategory;
    let event_action = "Top Up Failed";
    let label = `collectItId: ${store.getState().auth.currentUser.collectitid}`;
    addEvent(category, event_action, label);
  }

  return next(action);
};

function addEvent(category,action, label){
  //category, action, label, value
  label ?
  (
    ReactGA.event({
      category: category,
      action: action,
      label: label
    })
  )
  :
  (
    ReactGA.event({
      category: category,
      action: action
    })
  )
}

  export default trackingMiddleware;