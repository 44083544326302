import React from 'react';
import { Screen } from '../../components';
import { Trans } from 'react-i18next';

function NotFound(props) {
	return (
		<Screen>
			<div
				style={{
					flex: 1,
					display: 'flex',
					alignItems: 'center',
					justifyContent: 'center'
				}}>
				<h1>
					<Trans>Oops... Page not found</Trans>
				</h1>
			</div>
		</Screen>
	);
}

export default NotFound;
