import { createReducer, createAction } from 'redux-starter-kit';
import apiConfig from '../config/apiConfig';
const initialState = {
	fetching: false,
	error: '',
	initializing: false,
	currentUser: undefined,
	accessToken: sessionStorage.getItem('@omni:token'),
	refreshToken: sessionStorage.getItem('@omni:refresh')
};
export const signOut = createAction('@@auth/SIGNED_OUT');

const signInFailed = createAction('@@auth/SIGN_IN_FAILED');
export const signInSuccess = createAction('@@auth/SIGN_IN_SUCCESS');
const actions = {
	[signInSuccess]: (state, action) => {
		state.fetching = false;
		//state.currentUser = action.payload.user;
		state.currentUser = JSON.parse(action.payload.user.user);
		state.accessToken = action.payload.accessToken;
		state.refreshToken = action.payload.refreshToken;
	},
	[signInFailed]: (state, action) => {
		state.fetching = false;
		state.error = action.payload;
	},
	'@@auth/SIGNED_OUT': (state, action) => initialState,
	'@@auth/init': (state, action) => {
		state.initializing = true;
	},
	'@@auth/init_success': (state, action) => {
		state.initializing = false;
		state.currentUser = action.payload;
	},
	'@@auth/init_failed': (state, action) => {
		state.initializing = false;
	}
};
const authReducer = createReducer(initialState, actions);
export default authReducer;

export function signIn({ email, password }, cb) {
	return function(dispatch, getState) {
		fetch(apiConfig.apiUrl + '/token/', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json'
			},
			body: JSON.stringify({
				email,
				password
			})
		})
			.then(async res => {
				if (!res.ok) {
					cb(null);
					return dispatch(signInFailed('FAILED'));
				}
				const user = await res.json();
				console.log(user);

				cb(user);
				if (!user) {
					return dispatch(signInFailed('FAILED'));
				}
				dispatch(
					signInSuccess({
						accessToken: user.access,
						refreshToken: user.refresh,
						user
					})
				);
			})
			.catch(() => {
				cb(null);
				dispatch(signInFailed('FAILED'));
			});
	};
}

export function initAuth() {
	return function(dispatch, getState) {
		const token = sessionStorage.getItem('@omni:token');
		const refresh = sessionStorage.getItem('@omni:refresh');
		if (!token && !refresh) return;
		dispatch({ type: '@@auth/init' });
		fetch(apiConfig.apiUrl + '/auth/users/me', {
			headers: {
				Authorization: 'Bearer ' + token
			}
		})
			.then(async res => {
				if (!res.ok) {
					return dispatch({ type: '@@auth/init_failed' });
				}
				const payload = await res.json();
				return dispatch({
					type: '@@auth/init_success',
					payload
				});
			})
			.catch(e => {
				console.log(e);
				dispatch({ type: '@@auth/init_failed' });
			});
	};
}
