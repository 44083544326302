export default store => next => action => {
	if (true || action.type !== 'LOCALE_CHANGED') {
		return next(action);
	}
	const localRequest = {
		type: 'API',
		payload: {
			method: 'POST',
			resource: 'LOCALE',
			body: { LanguageCode: action.payload },
			url: 'http://140.82.59.71:7077/OmniApi/SetLanguage'
		}
	};
	next(localRequest);
	next(action);
};
