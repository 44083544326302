import React from 'react';
import {
	Screen,
	NavBar,
	ScreenCard,
	ScreenCardSection,
	ShippingInfoForm,
	Input,
	RadioGroup
} from '../../components';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';
import './order.css';
import { connect } from 'react-redux';
import { reduxForm, Field } from 'redux-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
	orderItemsCountSelector,
	orderItemsTotalPriceSelector,
	orderTotalPriceSelector
} from '../../ducks/order';
import validate from '../../utils/validator';
import { formatPrice } from '../../utils/priceFormatter';
import ContactInformationForm from '../../components/ContactInformationForm/ContactInformationForm';
function Order(props) {
	/* 	React.useEffect(() => {
		if (!props.isRouteAllowed) {
			props.history.replace('/');
		}
	}, []); */
	const { t } = useTranslation();
	const {
		match: {
			params: { locale, category }
		}
	} = props;
	const isRTL = locale === 'he';
	return (
		<Screen title={t('titles:' + category)}>
			<div
				className={classNames({
					'order-screen-container': true,
					rtl: isRTL
				})}>
				<div className="order-screen-heading">
					<span>{t('Stage {{stage}} of 3', { stage: 2 })}</span>
				</div>
				<ScreenCard
					title={t('Order summary')}
					subtitle={
						<div className="payment-order-counter">
							<div>
								<span>
									{t('You selected {{count}} SIM cards', {
										count: props.itemsCount
									})}
								</span>
							</div>

							<div>
								<span>{formatPrice(props.totalItemsPrice)}</span>
							</div>
						</div>
					}>
					<ScreenCardSection
						title={t('Please select one of the following options') + ':'}>
						<Field
							name="shippingMethod"
							component={function(field) {
								return (
									<RadioGroup
										{...field.input}
										options={props.shippingMethods.map(item => {
											return {
												value: item.id,
												label: (
													<RadioLabel
														price={
															formatPrice(item.price, item.currency) ||
															t('Free')
														}
														label={item.title[locale]}
													/>
												)
											};
										})}
									/>
								);
							}}
						/>

						<hr />
						<div className="payment-order-summary">
							<span>{t('Order summary and price')}</span>
							<span className="payment-order-summary price">
								{formatPrice(props.totalPrice, 'usd')}
							</span>
						</div>
						<div className="payment-note">
							<img
								src={process.env.PUBLIC_URL + '/assets/red-star.png'}
								alt="note"
							/>
							<span>
								{t(
									'Please note that orders received on Fridays, Saturdays and Holidays will be processed on the following business day'
								)}
							</span>
						</div>
					</ScreenCardSection>
					<ScreenCardSection title={t('Shipping information') + ':'}>
						<ContactInformationForm />
						<ShippingInfoForm />
						<Field
							name="comments"
							component={field => {
								const values = field.input.value
									? field.input.value.split(',')
									: [''];
								return (
									<div>
										<div>
											<span>
												{t('Serial number of Omnitelecom SIM card you own')}
											</span>
										</div>
										{values.map((item, index) => {
											return (
												<div
													className="payment-omni-number-input-container"
													key={`sim_${index}`}>
													<Input
														value={item}
														onChange={e => {
															const val = e.target.value;
															let newVal = [...values];
															newVal[index] = val;
															field.input.onChange(newVal.join(','));
														}}
													/>
													<FontAwesomeIcon
														style={{ cursor: 'pointer' }}
														icon="plus-circle"
														onClick={() =>
															field.input.onChange(field.input.value + ',')
														}
													/>
												</div>
											);
										})}
									</div>
								);
							}}
						/>
					</ScreenCardSection>
				</ScreenCard>

				<NavBar
					disabled={!props.valid}
					next={props.match.url.replace('order', 'payment')}
					prev={props.match.url.replace('order', '')}
				/>
			</div>
		</Screen>
	);
}

function RadioLabel({ label, price }) {
	return (
		<div
			style={{
				flex: 1,
				display: 'flex',
				flexDirection: 'row',
				justifyContent: 'space-between'
			}}>
			<span>{label}</span>
			<span>{price}</span>
		</div>
	);
}

const mapStateToProps = state => {
	return {
		isRouteAllowed: Object.keys(state.order.items).length > 0,
		shippingMethods: state.shippingMethods.allIds.map(
			id => state.shippingMethods.byId[id]
		),
		itemsCount: orderItemsCountSelector(state),
		totalItemsPrice: orderItemsTotalPriceSelector(state),
		totalPrice: orderTotalPriceSelector(state),
		order: state.order
	};
};
export default connect(mapStateToProps)(
	reduxForm({ form: 'order', destroyOnUnmount: false, validate: validate })(
		Order
	)
);
