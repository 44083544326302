import React from 'react';
import '../ShippingInfoForm/shipping-info-form.css';
import { useTranslation } from 'react-i18next';
import { reduxForm, Field, clearSubmitErrors } from 'redux-form';
import './ContactUsForm.css';
import TextArea from '../../components/TextArea/TextArea'
import classNames from 'classnames';
import ContactUs from '../../screens/ContactUs/ContactUs';
import { postContactUs, clearState} from '../../ducks/contactUs';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';
import {validateEmail, maxLength} from '../../utils/validator';

//Enter a valid email address
//Enter a valid phone number

const validate = values => {
	const errors = {}
	if(values.fullName && !maxLength(80)(values.fullName))
		errors.fullName = 'No more than 80 characters';
    if(!validateEmail(values.email))
		errors.email = 'Enter a valid email address';
	else if(!maxLength(120)(values.email))
		errors.email = 'No more than 120 characters';   
    if(!values.phoneNumber)
		errors.phoneNumber = 'Enter a valid phone number';
	else if(!maxLength(17)(values.phoneNumber))
		errors.phoneNumber = 'No more than 17 characters';
	if(values.message && !maxLength(30000)(values.message))
		errors.message = 'No more than 30000 characters'
    return errors;
}

function renderTextArea({input, label, severError, meta:{touched, error}}) {
	const { t } = useTranslation();
	return (
		<div className="fieldStyle">
			<TextArea {...input} placeholder = {label} className="inputStyle"/>
			{error && (
				<span className="inputError">
					{t('errors:' + t(error))}
				</span>
			)}
			{severError && <span className="inputError">{t('Oops! Something went wrong.')}</span>}
		</div>
	)
}


function renderField({
    input,
    label,
    type,
    meta: {touched, error, warning}
}) {
    const { t } = useTranslation();
	return (
		<div className="fieldStyle">
			<input
                {...input}
                placeholder = {label}
                type = {type}
                className="inputStyle"
			/>
			{touched && error && (
				<span className="inputError">
					{t('errors:' + t(error))}
				</span>
			)}
		</div>
	);
}

function ContactUsForm(props) {
	
	const handleSubmit = props.handleSubmit(values => {
		props.postContactUs(values);
	});
	const { t } = useTranslation();
	if (props.redirect) {
		return <Redirect to={props.match.url.replace('contact-us', 'contact-us-ty')} />;
	}

	return (
		<form className="ContactUsForm">
			<Field
				name="fullName"
                component={renderField}
                label={t('Full name')}
			/>
			<Field
				name="email"
				component={renderField}
				label={t('Email')}
			/>
            <Field
				name="phoneNumber"
				component={renderField}
				label={t('Phone number')}
			/>
            <Field
		    	name="message"
				component={renderTextArea}
				severError={props.failedSubmit}
                label={t("Enter your message here")}
		    />
            <div className="formFooter">
                <button className="submitButton" type="submit" disabled={props.loading} onClick={handleSubmit}>
					{props.loading ? (t('contact:Sending...')) : (t('contact:Submit'))}
                </button>
            </div>
		</form>
	);
}

ContactUsForm = reduxForm({
	form: 'contactUs',
    destroyOnUnmount: false,
	validate: validate
})(ContactUsForm);

const mapStateToProps = state => {
	let redirect = state.contactUs.success
	const failedSubmit = state.contactUs.error
	return {
		redirect,
		failedSubmit,
		loading: state.contactUs.fetching
	};
};

const dispatchProps = {
	postContactUs,
	clearState
};

export default connect(
	mapStateToProps,
	dispatchProps
)(ContactUsForm);
