import React, { Component, useEffect } from "react";
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";
import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faAngleDown,
  faLongArrowAltRight,
  faLongArrowAltLeft,
  faChevronLeft,
  faChevronRight,
  faPlusCircle,
  faCalendarAlt,
  faPlus,
  faMinus,
} from "@fortawesome/free-solid-svg-icons";
import { Provider, connect } from "react-redux";
import "./i18n";
import configureStore from "./ducks";
import {
  Home,
  Category,
  Payment,
  NotFound,
  Content,
  Order,
  PostPurchase,
  Countries,
  CountryRates,
  Admin,
} from "./screens";
import Manual from "./screens/Manual/Manual";
import ContactUs from "./screens/ContactUs/ContactUs";
import ContactUsTy from "./screens/ContactUs/ContactUsTy";
import { useTranslation } from "react-i18next";
import { initAuth } from "./ducks/auth";
//import { getSimPlanTypes } from './ducks/simPlanTypes';
import { ScrollToTop } from "./components";
import { getShippingMethods } from "./ducks/shippingMethods";
import {
  withAnalytics,
  configureAnalytics,
} from "./components/Tracking/withAnalytics";

configureAnalytics();

library.add(
  faAngleDown,
  faLongArrowAltLeft,
  faLongArrowAltRight,
  faChevronLeft,
  faCalendarAlt,
  faChevronRight,
  faPlusCircle,
  faPlus,
  faMinus
);
const store = configureStore();

class App extends Component {
  state = { ready: false };

  componentDidMount() {
    store.dispatch(initAuth());
    store.dispatch(getShippingMethods());
  }

  render() {
    return (
      <Provider store={store}>
        <MainNavigation />
      </Provider>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    locale: state.appState.locale,
  };
};
const LocaleRedirect = connect(mapStateToProps)((props) => {
  const { i18n } = useTranslation();
  const { locale } = props;
  i18n.changeLanguage(locale);

  return <Redirect to={"/" + locale} />;
});

export default App;

const MainNavigation = (props) => {
  if (props.loading) return <span>wait...</span>;
  return (
    <React.Suspense fallback={<div />}>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route exact path="/" component={withAnalytics(LocaleRedirect)} />
            <Route path="/manuals" component={withAnalytics(Manual)} />
            <Route exact path="/:locale" component={withAnalytics(Home)} />
            <Route
              exact
              path="/:locale/countries"
              component={withAnalytics(Countries)}
            />
            <Route
              path="/:locale/contact-us"
              component={withAnalytics(ContactUs)}
            />
            <Route
              path="/:locale/contact-us-ty"
              component={withAnalytics(ContactUsTy)}
            />
            <Route
              path="/:locale/country-rates"
              component={withAnalytics(CountryRates)}
            />
            <Route
              exact
              path="/:locale(he|en)/:category(global-sim|israeli-sim|european-sim|american-sim|esim-sim)"
              component={withAnalytics(Category)}
            />
            <Route
              path="/:locale(he|en)/:category(global-sim|israeli-sim|european-sim|american-sim|esim-sim)/order"
              component={withAnalytics(Order)}
            />
            <Route
              path="/:locale(he|en)/:category(global-sim|israeli-sim|european-sim|american-sim|esim-sim)/payment"
              component={withAnalytics(Payment)}
            />
            <Route
              path="/:locale(he|en)/:category(global-sim|israeli-sim|european-sim|american-sim|esim-sim)/thank-you"
              component={withAnalytics(PostPurchase)}
            />
            <Route
              path="/:locale(he|en)/terms-of-service"
              component={withAnalytics(Content)}
            />
            <Route
              path="/:locale(he|en)/privacy-policy"
              component={withAnalytics(Content)}
            />
            <Route path="/:locale/admin" component={withAnalytics(Admin)} />
            <Route path="*" component={withAnalytics(NotFound)} />
          </Switch>
        </ScrollToTop>
      </Router>
    </React.Suspense>
  );
};
