import React from 'react';
import { withRouter, NavLink } from 'react-router-dom';
import './admin-tab-bar.css';
import { useTranslation } from 'react-i18next';
function AdminTabBar(props) {
	const { t } = useTranslation();
	const isActive = route => {
		return props.location.pathname.includes(props.match.url + route);
	};
	return (
		<div className="admin-tab-bar">
			<NavLink
				isActive={() => isActive('/sign-out')}
				activeClassName="admin-tab-bar-selected"
				to={props.match.url + '/sign-out'}>
				{t('Sign out')}
			</NavLink>
			<NavLink
				isActive={() => isActive('/purchase-history')}
				activeClassName="admin-tab-bar-selected"
				to={props.match.url + '/purchase-history'}>
				{t('Purchase history')}
			</NavLink>
			<NavLink
				isActive={() => isActive('/calls-history')}
				activeClassName="admin-tab-bar-selected"
				to={props.match.url + '/calls-history'}>
				{t('Calls history')}
			</NavLink>
			<NavLink
				isActive={() => isActive('/rates')}
				activeClassName="admin-tab-bar-selected"
				to={props.match.url + '/rates'}>
				{t('Rates')}
			</NavLink>
			<NavLink
				isActive={() => isActive('/status')}
				activeClassName="admin-tab-bar-selected"
				to={props.match.url + '/status'}>
				{t('Status')}
			</NavLink>
		</div>
	);
}

export default withRouter(AdminTabBar);
