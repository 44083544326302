import React from 'react';
import './status.css';
import { Redirect } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Button } from '../../components';
import classNames from 'classnames';
import Select from 'react-select';
import { connect } from 'react-redux';
import { reduxForm, Field, formValueSelector } from 'redux-form';
const UNIT_PRICE = 10;
const options = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10].map(item => ({
	value: item,
	label: item
}));
let Credits = props => {
	const { t } = useTranslation();
	if (props.redirect) {
		return <Redirect to={props.match.url.replace('/credits', '')} />;
	}
	return (
		<div
			className={classNames({
				'admin-status-container': true,
				rtl: props.match.params.locale === 'he'
			})}>
			<div className="admin-status-header">
				<h3>{t('Payment')}</h3>
			</div>
			<div>
				<table className="admin-credits-table">
					<thead>
						<tr>
							<td>
								<span>{t('Unit price')}</span>
							</td>
							<td>
								<span>{t('Quantity')}</span>
							</td>
							<td>
								<span>{t('Total price')}</span>
							</td>
						</tr>
					</thead>
					<tbody>
						<tr>
							<td>{UNIT_PRICE}$</td>
							<td>
								<Field
									name="quantity"
									component={({ input }) => {
										return (
											<Select
												options={options}
												value={{ value: input.value, label: input.value }}
												onChange={c => input.onChange(c.value)}
												theme={theme => {
													return {
														...theme,

														borderRadius: 0
													};
												}}
												placeholder={t('Select')}
											/>
										);
									}}
								/>
							</td>
							<td>${props.total}</td>
						</tr>
					</tbody>
					<tfoot>
						<tr>
							<td colSpan={3}>
								<Button
									disabled={!props.total}
									onClick={() =>
										props.history.push(
											props.match.url.replace('credits', 'purchase')
										)
									}>
									{t('Continue to payment')}
								</Button>
							</td>
						</tr>
					</tfoot>
				</table>
			</div>
		</div>
	);
};

const formSelector = formValueSelector('topup');
const mapStateToProps = state => {
	const total = formSelector(state, 'quantity');
	return {
		redirect: !state.account.selectedSimNumber,
		total: total ? total * UNIT_PRICE : 0
	};
};

const dispatchProps = {};

Credits = reduxForm({ form: 'topup', destroyOnUnmount: false })(Credits);
export default connect(
	mapStateToProps,
	dispatchProps
)(Credits);
